"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../types");
const url = {
    [types_1.EvironmentTypes.PRODUCTION]: {
        API_PATH: '/server/api',
        ROOT_URL: 'https://app.epiloguewills.com',
        MARKETING_URL: 'https://epiloguewills.com',
        QUESTIONNAIRE_URL: 'https://app.epiloguewills.com/questionnaire',
        DASHBOARD_URL: 'https://app.epiloguewills.com/dashboard',
        PARTIALLY: 'https://partial.ly/api',
    },
    [types_1.EvironmentTypes.STAGING]: {
        API_PATH: '/server/api',
        ROOT_URL: 'https://app-staging.epiloguewills.com',
        MARKETING_URL: 'https://epiloguewills.com',
        QUESTIONNAIRE_URL: 'https://app-staging.epiloguewills.com/questionnaire',
        DASHBOARD_URL: 'https://app-staging.epiloguewills.com/dashboard',
        PARTIALLY: 'https://demo.partial.ly/api',
    },
    [types_1.EvironmentTypes.DEVELOPMENT]: {
        API_PATH: '/api',
        ROOT_URL: 'http://localhost:3000',
        MARKETING_URL: 'http://localhost:3000',
        QUESTIONNAIRE_URL: 'http://localhost:3000/questionnaire',
        DASHBOARD_URL: 'http://localhost:3000/dashboard',
        PARTIALLY: 'https://demo.partial.ly/api',
    },
    [types_1.EvironmentTypes.TEST]: {
        API_PATH: '/api',
        ROOT_URL: 'http://localhost:3000',
        MARKETING_URL: 'http://localhost:3000',
        QUESTIONNAIRE_URL: 'http://localhost:3000/questionnaire',
        DASHBOARD_URL: 'http://localhost:3000/dashboard',
        PARTIALLY: 'https://demo.partial.ly/api',
    },
    [types_1.EvironmentTypes.CYPRESS]: {
        API_PATH: '/api',
        ROOT_URL: 'http://localhost:3000',
        MARKETING_URL: 'http://localhost:3000',
        QUESTIONNAIRE_URL: 'http://localhost:3000/questionnaire',
        DASHBOARD_URL: 'http://localhost:3000/dashboard',
        PARTIALLY: 'https://demo.partial.ly/api',
    },
};
exports.default = (environment, urlType) => {
    return url[environment][urlType];
};
