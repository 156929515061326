import _omit from 'lodash/omit'

import { CONSTANTS } from '@epilogue/common'

import fallbackLanguage from '../utils/fallbackLanguage'

const section = CONSTANTS.questionnaireSectionTypes.CHARITIES.id

export default {
  id: section,
  description: { tKey: 'questionnaire.charities:description' },
  trigger: { 'charityPartner.name': { $exists: true } },
  questions: [
    {
      id: 1642619110,
      pathname: 'intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.charities:intro.highlightPillText',
      },
      label: { tKey: 'questionnaire.charities:intro.label' },
      subLabel: { tKey: 'questionnaire.charities:intro.subLabel' },
    },

    {
      id: 1644267723,
      pathname: 'charity-partner-mirror-family-gift',
      section,

      label: ({
        charityPartner,
        answerStore: { charityPartnerFamilyLegacyGiftDetails },
      }) => {
        const { displayName } = charityPartner

        const partnerGenderKey =
          charityPartnerFamilyLegacyGiftDetails?.partnerGender === 'male'
            ? 'male'
            : charityPartnerFamilyLegacyGiftDetails?.partnerGender === 'female'
              ? 'female'
              : 'other'

        const giftValueKey = charityPartnerFamilyLegacyGiftDetails?.isPercent
          ? 'percent'
          : 'dollar'

        return {
          tKey: `questionnaire.charities:charity-partner-mirror-family-gift.label.${partnerGenderKey}.${giftValueKey}`,
          options: {
            charityPartnerDisplayName: fallbackLanguage(displayName),
            charityPartnerFamilyLegacyGiftDetailsPartnerFirstName:
              charityPartnerFamilyLegacyGiftDetails?.partnerFirstName,
            amount: charityPartnerFamilyLegacyGiftDetails?.amount,
          },
        }
      },

      label2: {
        tKey: 'questionnaire.charities:charity-partner-mirror-family-gift.label2',
      },

      subLabel: ({
        charityPartner,
        answerStore: { charityPartnerFamilyLegacyGiftDetails },
      }) => {
        const { displayName } = charityPartner

        const partnerGenderKey =
          charityPartnerFamilyLegacyGiftDetails?.partnerGender === 'male'
            ? 'male'
            : charityPartnerFamilyLegacyGiftDetails?.partnerGender === 'female'
              ? 'female'
              : 'other'

        return {
          tKey: `questionnaire.charities:charity-partner-mirror-family-gift.subLabel.${partnerGenderKey}`,
          options: {
            charityPartnerDisplayName: fallbackLanguage(displayName),
            charityPartnerFamilyLegacyGiftDetailsPartnerFirstName:
              charityPartnerFamilyLegacyGiftDetails?.partnerFirstName,
          },
        }
      },

      graphic: { image: CONSTANTS.standardGraphics.CHARITY_LOGO },

      sideEffects: [
        {
          trigger: { charityPartnerMirrorFamilyGift: 'yes' },
          questionFragments: ({ answerStore }) => {
            const { charityPartnerFamilyLegacyGiftDetails } = answerStore
            return {
              allToPartner: undefined,
              charityPartnerGiftDetails: _omit(
                charityPartnerFamilyLegacyGiftDetails,
                ['partnerFirstName', 'partnerGender'],
              ),
            }
          },
        },
        {
          trigger: { charityPartnerMirrorFamilyGift: 'no' },
          questionFragments: { charityPartnerGiftDetails: undefined },
        },
      ],

      fields: [
        {
          name: 'charityPartnerMirrorFamilyGift',
          type: 'quickRadio',
          reviewLabel: ({
            charityPartner: { displayName },
            answerStore: { charityPartnerFamilyLegacyGiftDetails },
          }) => ({
            tKey: charityPartnerFamilyLegacyGiftDetails?.partnerFirstName
              ? 'questionnaire.charities:charity-partner-mirror-family-gift.fields.charityPartnerMirrorFamilyGift.reviewLabel.case1'
              : 'questionnaire.charities:charity-partner-mirror-family-gift.fields.charityPartnerMirrorFamilyGift.reviewLabel.case2',
            options: {
              charityPartnerDisplayName: fallbackLanguage(displayName),
              charityPartnerFamilyLegacyGiftDetailsPartnerFirstName:
                charityPartnerFamilyLegacyGiftDetails?.partnerFirstName,
            },
          }),
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            {
              value: 'no',
              label: ({
                answerStore: { charityPartnerFamilyLegacyGiftDetails },
              }) => ({
                tKey: charityPartnerFamilyLegacyGiftDetails?.partnerFirstName
                  ? `questionnaire.charities:charity-partner-mirror-family-gift.fields.charityPartnerMirrorFamilyGift.options.no.label.case1`
                  : `questionnaire.charities:charity-partner-mirror-family-gift.fields.charityPartnerMirrorFamilyGift.options.no.label.case2`,
                options: {
                  charityPartnerFamilyLegacyGiftDetailsPartnerFirstName:
                    charityPartnerFamilyLegacyGiftDetails?.partnerFirstName,
                },
              }),
              reviewLabel: { tKey: 'common:no' },
            },
          ],
        },
        {
          name: 'charityPartnerGiftDetails',
          type: 'preventCleanse',
          hideOnReview: true,
          optional: true,
        },
      ],
      trigger: {
        hasPartner: 'yes',
        charityPartnerFamilyLegacyGiftDetails: { $exists: true },
      },
    },

    {
      id: 1642619111,
      pathname: 'charity-partner-include-gift',
      section,
      graphic: { image: CONSTANTS.standardGraphics.CHARITY_LOGO },

      label: ({
        charityPartner,
        answerStore: {
          noPartnerSpecific,
          charityPartnerMirrorFamilyGift,
          charityPartnerFamilyLegacyGiftDetails,
        },
      }) => {
        const partnerGenderKey =
          charityPartnerFamilyLegacyGiftDetails?.partnerGender === 'male'
            ? 'male'
            : charityPartnerFamilyLegacyGiftDetails?.partnerGender === 'female'
              ? 'female'
              : 'other'

        const giftValueKey = charityPartnerFamilyLegacyGiftDetails?.isPercent
          ? 'percent'
          : 'dollar'

        return {
          tKey:
            noPartnerSpecific === 'widowed' &&
            charityPartnerFamilyLegacyGiftDetails
              ? `questionnaire.charities:charity-partner-include-gift.label.case2.${partnerGenderKey}.${giftValueKey}`
              : 'questionnaire.charities:charity-partner-include-gift.label.case1',
          options: {
            charityPartnerFamilyLegacyGiftDetailsPartnerFirstName:
              charityPartnerFamilyLegacyGiftDetails?.partnerFirstName,
            amount: charityPartnerFamilyLegacyGiftDetails?.amount,
            charityPartnerMirrorFamilyGiftModifier:
              charityPartnerMirrorFamilyGift === 'no' ? 'different' : '',
            charityPartnerDisplayName: fallbackLanguage(
              charityPartner?.displayName,
            ),
          },
        }
      },

      label2: ({ charityPartner }) =>
        charityPartner.shortDescription
          ? {
              tKey: 'questionnaire.charities:charity-partner-include-gift.label2',
              options: {
                charityPartnerShortDescription: fallbackLanguage(
                  charityPartner?.shortDescription,
                ),
              },
            }
          : undefined,

      subLabel: ({ charityPartner }) =>
        charityPartner.longDescription
          ? {
              tKey: 'questionnaire.charities:charity-partner-include-gift.subLabel',
              options: {
                charityPartnerLongDescription: fallbackLanguage(
                  charityPartner?.longDescription,
                ),
              },
            }
          : undefined,

      sideEffects: [
        {
          trigger: { charityPartnerGiftInclude: 'no' },
          questionFragments: { charityPartnerGiftDetails: undefined },
        },
      ],
      fields: [
        {
          name: 'charityPartnerGiftInclude',
          type: 'quickRadio',
          reviewLabel: ({ charityPartner }) => ({
            tKey: 'questionnaire.charities:charity-partner-include-gift.fields.charityPartnerGiftInclude.reviewLabel',
            options: {
              charityPartnerDisplayName: fallbackLanguage(
                charityPartner.displayName,
              ),
            },
          }),
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $or: [
          { hasPartner: 'no' },
          { charityPartnerFamilyLegacyGiftDetails: { $exists: false } },
          {
            hasPartner: 'yes',
            charityPartnerMirrorFamilyGift: 'no',
            charityPartnerFamilyLegacyGiftDetails: { $exists: true },
          },
        ],
      },
    },

    {
      id: 1642619113,
      pathname: 'charity-partner-gift-timing',
      section,
      type: 'quickRadioWithLink',
      graphic: { image: CONSTANTS.standardGraphics.CHARITY_LOGO },

      label: ({
        charityPartner: { displayName },
        answerStore: { partnerFirstName },
      }) => ({
        tKey: 'questionnaire.charities:charity-partner-gift-timing.label',
        options: {
          charityPartnerDisplayName: fallbackLanguage(displayName),
          partnerFirstName,
        },
      }),

      label2: ({ charityPartner: { displayName } }) => ({
        tKey: 'questionnaire.charities:charity-partner-gift-timing.label2',
        options: { charityPartnerDisplayName: fallbackLanguage(displayName) },
      }),

      linkLabel: {
        tKey: 'questionnaire.charities:charity-partner-gift-timing.linkLabel',
      },
      linkHref: 'https://epiloguewills.com/blog/family-legacy-gift/',

      sideEffects: [
        {
          trigger: {
            charityPartnerGiftType: '%',
            charityPartnerGiftTiming: 'familyLegacy',
          },
          questionFragments: { allToPartner: undefined },
        },
      ],

      interceptModal: [
        {
          label: ({ charityPartner, answerStore: { partnerFirstName } }) => ({
            tKey: 'questionnaire.charities:charity-partner-gift-timing.interceptModal.item1.label',
            options: {
              partnerFirstName,
              charityPartnerDisplayName: fallbackLanguage(
                charityPartner?.displayName,
              ),
            },
          }),

          subLabel: ({ answerStore: { partnerFirstName } }) => ({
            tKey: 'questionnaire.charities:charity-partner-gift-timing.interceptModal.item1.subLabel',
            options: { partnerFirstName },
          }),

          primaryButtonLabel: { tKey: 'common:iUnderstand' },
          primaryButtonIcon: true,
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
          },
          secondaryButtonLabel: { tKey: 'common:close' },
          secondaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.CLOSE_MODAL,
          },
          trigger: { charityPartnerGiftTiming: 'familyLegacy' },
        },
      ],

      fields: [
        {
          name: 'charityPartnerGiftTiming',
          type: 'quickRadio',

          reviewLabel: ({ charityPartner: { displayName } }) => ({
            tKey: 'questionnaire.charities:charity-partner-gift-timing.fields.charityPartnerGiftTiming.reviewLabel',
            options: {
              charityPartnerDisplayName: fallbackLanguage(displayName),
            },
          }),

          options: [
            {
              value: 'familyLegacy',
              label: {
                tKey: 'questionnaire.charities:charity-partner-gift-timing.fields.charityPartnerGiftTiming.options.familyLegacy.label',
              },
              subLabel: ({ answerStore: { partnerFirstName } }) => ({
                tKey: 'questionnaire.charities:charity-partner-gift-timing.fields.charityPartnerGiftTiming.options.familyLegacy.subLabel',
                options: { partnerFirstName },
              }),
              reviewLabel: ({ answerStore: { partnerFirstName } }) => ({
                tKey: 'questionnaire.charities:charity-partner-gift-timing.fields.charityPartnerGiftTiming.options.familyLegacy.reviewLabel',
                options: { partnerFirstName },
              }),
            },
            {
              value: 'personal',
              label: {
                tKey: 'questionnaire.charities:charity-partner-gift-timing.fields.charityPartnerGiftTiming.options.personal.label',
              },
              subLabel: ({ answerStore: { partnerFirstName } }) => ({
                tKey: 'questionnaire.charities:charity-partner-gift-timing.fields.charityPartnerGiftTiming.options.personal.subLabel',
                options: { partnerFirstName },
              }),
              reviewLabel: ({ answerStore: { partnerFirstName } }) => ({
                tKey: 'questionnaire.charities:charity-partner-gift-timing.fields.charityPartnerGiftTiming.options.personal.reviewLabel',
                options: { partnerFirstName },
              }),
            },
          ],
        },
      ],
      trigger: {
        $and: [
          { charityPartnerGiftInclude: 'yes' },
          { hasPartner: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerGender: { $exists: true } },
          { partnerReference: { $exists: true } },
        ],
      },
    },

    {
      id: 1642619114,
      pathname: 'charity-partner-gift-type',
      section,
      graphic: { image: CONSTANTS.standardGraphics.CHARITY_LOGO },
      label: ({ charityPartner: { displayName } }) => ({
        tKey: 'questionnaire.charities:charity-partner-gift-type.label',
        options: { charityPartnerDisplayName: fallbackLanguage(displayName) },
      }),
      subLabel: {
        tKey: 'questionnaire.charities:charity-partner-gift-type.subLabel',
      },
      fields: [
        {
          name: 'charityPartnerGiftType',
          type: 'quickRadio',
          reviewLabel: ({ charityPartner: { displayName } }) => ({
            tKey: 'questionnaire.charities:charity-partner-gift-type.fields.charityPartnerGiftType.reviewLabel',
            options: {
              charityPartnerDisplayName: fallbackLanguage(displayName),
            },
          }),
          options: [
            {
              value: '$',
              label: {
                tKey: 'questionnaire.charities:charity-partner-gift-type.fields.charityPartnerGiftType.options.$.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.charities:charity-partner-gift-type.fields.charityPartnerGiftType.options.$.reviewLabel',
              },
            },
            {
              value: '%',
              label: {
                tKey: 'questionnaire.charities:charity-partner-gift-type.fields.charityPartnerGiftType.options.%.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.charities:charity-partner-gift-type.fields.charityPartnerGiftType.options.%.reviewLabel',
              },
            },
          ],
        },
      ],
      sideEffects: [
        {
          trigger: {
            $or: [
              { charityPartnerGiftType: '$' },
              { charityPartnerGiftType: '%' },
            ],
          },
          questionFragments: { charityPartnerGiftAmount: '' },
        },
        {
          trigger: {
            charityPartnerGiftType: '%',
            charityPartnerGiftTiming: 'familyLegacy',
          },
          questionFragments: { allToPartner: undefined },
        },
      ],

      trigger: { charityPartnerGiftInclude: 'yes' },
    },

    {
      id: 1642619115,
      pathname: 'charity-partner-gift-amount',
      type: 'charityPartnerGiftAmount',
      section,
      graphic: { image: CONSTANTS.standardGraphics.CHARITY_LOGO },
      label: ({ charityPartner: { displayName } }) => ({
        tKey: 'questionnaire.charities:charity-partner-gift-amount.label',
        options: { charityPartnerDisplayName: fallbackLanguage(displayName) },
      }),
      fields: [
        {
          name: 'charityPartnerGiftAmount',
          type: 'text',
          reviewLabel: ({ charityPartner: { displayName } }) => ({
            tKey: 'questionnaire.charities:charity-partner-gift-amount.fields.charityPartnerGiftAmount.reviewLabel',
            options: {
              charityPartnerDisplayName: fallbackLanguage(displayName),
            },
          }),
        },
        {
          name: 'charityPartnerGiftDetails',
          type: 'preventCleanse',
          hideOnReview: true,
          optional: true,
        },
      ],
      trigger: {
        charityPartnerGiftInclude: 'yes',
        charityPartnerGiftType: { $exists: true },
      },
    },

    {
      id: 1642619117,
      pathname: 'other-charitable-gifts',
      section,

      label: ({
        answerStore: {
          hasPartner,
          charityPartnerGiftInclude,
          charityPartnerGiftTiming,
        },
      }) =>
        hasPartner === 'yes' && charityPartnerGiftInclude === 'yes'
          ? charityPartnerGiftTiming === 'familyLegacy'
            ? {
                tKey: 'questionnaire.charities:other-charitable-gifts.label.case1',
              }
            : {
                tKey: 'questionnaire.charities:other-charitable-gifts.label.case2',
              }
          : {
              tKey: 'questionnaire.charities:other-charitable-gifts.label.case3',
            },

      subLabel: ({ answerStore }) => {
        const {
          charityPartnerFamilyLegacyGiftDetails,
          charityPartnerMirrorFamilyGift,
          charityPartnerGiftTiming,
          partnerFirstName,
          hasChildren,
          hasPartner,
        } = answerStore

        return hasPartner === 'no'
          ? hasChildren === 'no'
            ? {
                tKey: 'questionnaire.charities:other-charitable-gifts.subLabel.noChildren',
              }
            : ''
          : charityPartnerGiftTiming === 'familyLegacy'
            ? partnerFirstName
              ? {
                  tKey:
                    hasChildren === 'no'
                      ? 'questionnaire.charities:other-charitable-gifts.subLabel.case1b'
                      : 'questionnaire.charities:other-charitable-gifts.subLabel.case1',
                  options: { partnerFirstName },
                }
              : {
                  tKey:
                    hasChildren === 'no'
                      ? 'questionnaire.charities:other-charitable-gifts.subLabel.case2b'
                      : 'questionnaire.charities:other-charitable-gifts.subLabel.case2',
                }
            : charityPartnerGiftTiming === 'personal'
              ? partnerFirstName
                ? {
                    tKey:
                      hasChildren === 'no'
                        ? 'questionnaire.charities:other-charitable-gifts.subLabel.case3b'
                        : 'questionnaire.charities:other-charitable-gifts.subLabel.case3',
                    options: { partnerFirstName },
                  }
                : {
                    tKey:
                      hasChildren === 'no'
                        ? 'questionnaire.charities:other-charitable-gifts.subLabel.case4b'
                        : 'questionnaire.charities:other-charitable-gifts.subLabel.case4',
                  }
              : charityPartnerMirrorFamilyGift === 'yes'
                ? charityPartnerFamilyLegacyGiftDetails?.partnerFirstName
                  ? {
                      tKey:
                        hasChildren === 'no'
                          ? 'questionnaire.charities:other-charitable-gifts.subLabel.case5b'
                          : 'questionnaire.charities:other-charitable-gifts.subLabel.case5',
                      options: {
                        partnerFirstName:
                          charityPartnerFamilyLegacyGiftDetails?.partnerFirstName,
                      },
                    }
                  : {
                      tKey:
                        hasChildren === 'no'
                          ? 'questionnaire.charities:other-charitable-gifts.subLabel.case6b'
                          : 'questionnaire.charities:other-charitable-gifts.subLabel.case6',
                    }
                : hasChildren === 'no'
                  ? {
                      tKey: 'questionnaire.charities:other-charitable-gifts.subLabel.noChildren',
                    }
                  : ''
      },

      fields: [
        {
          name: 'charitableGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.charities:other-charitable-gifts.fields.charitableGifts.reviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $or: [
          { hasPartner: 'no' },
          { hasPartner: 'yes', partnerFirstName: { $exists: true } },
        ],
      },
    },

    {
      id: 1642619118,
      pathname: 'other-charitable-gift-details',
      type: 'charitableGiftDetails',
      section,
      label: {
        tKey: 'questionnaire.charities:other-charitable-gift-details.label',
      },
      label2: {
        tKey: 'questionnaire.charities:other-charitable-gift-details.label2',
      },
      subLabel: {
        tKey: 'questionnaire.charities:other-charitable-gift-details.subLabel',
      },
      fields: [
        {
          name: 'charitableGiftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.charities:other-charitable-gift-details.fields.charitableGiftDetails.reviewLabel',
          },
        },
      ],
      trigger: { charitableGifts: 'yes' },
    },

    {
      id: 1720126697,
      pathname: 'charities-share-charitable-gift-details',
      section,
      label: {
        tKey: 'questionnaire.charities:charities-share-charitable-gift-details.label',
      },

      label2: ({ answerStore }) => {
        // If user is making gift to one "OTHER" charity
        if (
          !answerStore.charityPartnerGiftDetails &&
          answerStore?.charitableGiftDetails?.length === 1
        ) {
          return {
            tKey: 'questionnaire.charities:charities-share-charitable-gift-details.label2.singleOtherCharity',
          }
        }

        // User is making gift to multiple charities
        return {
          tKey: 'questionnaire.charities:charities-share-charitable-gift-details.label2.multipleCharities',
        }
      },

      fields: [
        {
          name: 'shareCharitableGiftDetails',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.charities:charities-share-charitable-gift-details.fields.shareCharitableGiftDetails.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        'charitableGiftDetails.0': { $exists: true },
      },
    },

    {
      id: 1723736664,
      pathname: 'charities-share-charitable-gift-details-partner',
      section,
      graphic: { image: CONSTANTS.standardGraphics.CHARITY_LOGO },
      label: {
        tKey: 'questionnaire.charities:charities-share-charitable-gift-details-partner.label',
      },

      label2: ({ charityPartner }) => ({
        tKey: 'questionnaire.charities:charities-share-charitable-gift-details-partner.label2',
        options: {
          charityPartnerDisplayName: fallbackLanguage(
            charityPartner?.displayName,
          ),
        },
      }),

      subLabel: ({ charityPartner }) => {
        if (charityPartner?.notifyDescription) {
          return {
            tKey: 'questionnaire.charities:charities-share-charitable-gift-details-partner.subLabel',
            options: {
              charityPartnerNotifyDescription: fallbackLanguage(
                charityPartner.notifyDescription,
              ),
            },
          }
        }
        return undefined
      },

      fields: [
        {
          name: 'shareCharitableGiftDetails',
          type: 'quickRadio',
          reviewLabel: ({ charityPartner }) => ({
            tKey: 'questionnaire.charities:charities-share-charitable-gift-details-partner.fields.shareCharitableGiftDetails.reviewLabel',
            options: {
              charityPartnerDisplayName: fallbackLanguage(
                charityPartner?.displayName,
              ),
            },
          }),
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        charityPartnerGiftInclude: 'yes',
        charitableGiftDetails: { $exists: false },
      },
    },

    {
      id: 1643649583,
      pathname: 'review',
      type: 'sectionReview',
      section,
      label: { tKey: 'questionnaire.charities:review.label' },
      subLabel: { tKey: 'questionnaire.charities:review.subLabel' },
    },
  ],
}
