import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'

import { CONSTANTS } from '@epilogue/common'
import LogRocket from 'logrocket'
import { createLogic } from 'redux-logic'

import addOnsActions from '../../../scenes/Dashboard/ducks/addOns/addOnsActions'
import appointmentsActions from '../../../scenes/Dashboard/ducks/appointments/appointmentsActions'
import bonusDocsActions from '../../../scenes/Dashboard/ducks/bonusDocs/bonusDocsActions'
import dashboardActions from '../../../scenes/Dashboard/ducks/dashboard/dashboardActions'
import documentsSelectors from '../../../scenes/Dashboard/ducks/documents/documentsSelectors'
import paymentsActions from '../../../scenes/Dashboard/ducks/payments/paymentsActions'
import data from '../../../scenes/Questionnaire/data'
import navigationActions from '../../../scenes/Questionnaire/ducks/navigation/navigationActions'
import questionnaireActions from '../../../scenes/Questionnaire/ducks/questionnaire/questionnaireActions'
import questionnaireSelectors from '../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import {
  cleansedAnswerStore,
  nameStore,
  triggeredQuestions,
} from '../../../scenes/Questionnaire/engine'
import amplitude from '../../lib/amplitude'
import { errorHandler, PATHS, request } from '../../request'
import { getReferrerFromCookies } from '../../utils/referrer'
import commonActions from '../commonActions'
import commonSelectors from '../commonSelectors'
import commonTypes from '../commonTypes'
import sharedAnswerCacheValues from './utils/sharedAnswerCacheValues'

const intercomAppId = 'xcpckn80'

const fetchCharityPartner = ({ referrer }) => {
  if (referrer?.ref === 'charity' && referrer?.reftag) {
    const charityPartnerId = referrer.reftag

    return request({
      method: 'GET',
      url: `${PATHS.CHARITY_PARTNER}/${charityPartnerId}`,
    })
  }
  return undefined
}

export default createLogic({
  name: 'authentication',
  type: commonTypes.CHECK_AUTH,
  async process({ getState, action }, dispatch, done) {
    const { fromQuestionnaire } = action.payload

    const world = commonSelectors.getWorld(getState())

    const documents = documentsSelectors.getDocuments(getState().dashboard)

    try {
      const res = await request({ method: 'GET', url: PATHS.USER_ME })

      /*
       *
       *
       * IF USER IS UNAUTHENTICATED
       *
       *
       */
      if (_isEmpty(res.data.payload)) {
        dispatch(dashboardActions.setUserProperties({ userId: null }))
        // Boot Intercom
        if (window.Intercom) window.Intercom('boot', { app_id: intercomAppId })

        // START: set charity partner //
        const charityPartnerRes = await fetchCharityPartner({
          referrer: getReferrerFromCookies(),
        })

        if (charityPartnerRes) {
          dispatch(
            commonActions.setCharityPartner({
              payload: _get(charityPartnerRes, 'data.payload'),
            }),
          )
        }
        // END: set charity partner //

        /*
         *
         *
         * IF USER IS AUTHENTICATED
         *
         *
         */
      } else {
        const {
          _id,
          email,
          codes,
          addOns,
          payments,
          isPartner,
          mailingList,
          appointments,
          questionnaire,
          bonusDocsAnswerStore,
          howDidYouHearAboutUs,
        } = res.data.payload

        dispatch(commonActions.setAuth(true))
        dispatch(
          dashboardActions.setUserProperties({
            email,
            codes,
            isPartner,
            userId: _id,
            mailingList,
            howDidYouHearAboutUs,
          }),
        )

        if (!_isEmpty(payments)) {
          dispatch(paymentsActions.setPayments(payments))
        }
        if (!_isEmpty(bonusDocsAnswerStore)) {
          dispatch(
            bonusDocsActions.setBonusDocsAnswerStore(bonusDocsAnswerStore),
          )
        }
        if (!_isEmpty(addOns)) {
          dispatch(addOnsActions.setAddOns(addOns))
        }
        if (!_isEmpty(appointments)) {
          dispatch(
            appointmentsActions.setAppointments({
              // Filters out any array items that are falsy
              appointments: appointments.filter(Boolean),
            }),
          )
        }

        const worldAnswerStore = _get(
          questionnaire,
          [CONSTANTS.worldTypes[world].answerStoreType],
          {},
        )
        const abTest = _get(questionnaire, 'abTest')
        const referrer = _get(questionnaire, 'referrer', {})

        /*
         * Only runs when the user is logging in to the app and
         * not in the middle of filling out the questionnaire.
         * (AKA: not creating an account 'in-flow')
         */
        if (!fromQuestionnaire) {
          // START: set charity partner //
          let charityPartner
          try {
            const charityPartnerRes = await fetchCharityPartner({
              referrer: questionnaire?.referrer,
            })

            if (charityPartnerRes) {
              dispatch(
                commonActions.setCharityPartner({
                  payload: _get(charityPartnerRes, 'data.payload'),
                }),
              )
            }
          } catch (error) {
            errorHandler({
              error,
              reportError: true,
              fallbackErrorMessage:
                'Unable to recognize the Epilogue charity-partner associated with your account. Please contact support@epiloguewills.com for assistance.',
              onError: (resolvedErrorMessage) =>
                dispatch(
                  commonActions.setGlobalErrorMessage(resolvedErrorMessage),
                ),
            })
          } finally {
            charityPartner = commonSelectors.getCharityPartner(getState())
          }
          // END: set charity partner //

          const theCleansedAnswerStore = cleansedAnswerStore({
            data,
            abTest,
            referrer,
            documents,
            charityPartner,
            /*
             * the cleansedAnswerStore is created with the current world's answerStore
             * from the DB and any values we need to pull from the answerCache for that
             * particular world. Right now, only the social world pulls the 'email' and
             * 'password' values from the answerCache to combine with the current social
             * answerStore before getting cleansed.
             */
            currentAnswerStore: {
              ..._pick(
                questionnaire.answerCache,
                sharedAnswerCacheValues(world, data),
              ),
              ...worldAnswerStore,
            },
          })

          // populate the questionnaire in redux after the user is authenticated
          const questionnairePropertiesToSet = {
            ..._omit(questionnaire, [
              '_id',
              '__v',
              'createdAt',
              'referrerInitiated',
              /*
               * omit all answerStore types so that the answerStore (below)
               * contains an object of the current world's answerStore values.
               */
              ...Object.values(CONSTANTS.worldTypes).map(
                (worldType) => worldType.answerStoreType,
              ),
              /*
               * omit all answerStoreUpdatedSinceGenerateTypes so that
               * the answerStoreUpdatedSinceGenerate (below) will contain the
               * value of the current world's answerStoreUpdatedSinceGenerate
               */
              ...Object.values(CONSTANTS.worldTypes).map(
                (worldType) => worldType.answerStoreUpdatedSinceGenerateType,
              ),
            ]),
            answerStore: theCleansedAnswerStore,
            questionnaireId: questionnaire._id,
            questionnaireFirstCompleted:
              questionnaire?.questionnaireFirstCompleted,
            answerCache: questionnaire.answerCache || theCleansedAnswerStore,
            answerStoreUpdatedSinceGenerate:
              questionnaire[
                CONSTANTS.worldTypes[world].answerStoreUpdatedSinceGenerateType
              ],
          }

          dispatch(
            questionnaireActions.setQuestionnaireProperties(
              _pick(
                questionnairePropertiesToSet,
                CONSTANTS.acceptedIncomingQuestionnaireProperties,
              ),
            ),
          )

          const accountCreated = questionnaireSelectors.getAccountCreated(
            getState().questionnaire,
          )

          const questionStack = triggeredQuestions({
            abTest,
            referrer,
            payments,
            isPartner,
            documents,
            theData: data,
            accountCreated,
            charityPartner,
            answerStore: theCleansedAnswerStore,
          })

          dispatch(navigationActions.updateQuestionStack({ questionStack }))

          const theNameStore = nameStore({
            referrer,
            isPartner,
            documents,
            charityPartner,
            answerStore: theCleansedAnswerStore,
            data,
            abTest,
          })
          dispatch(questionnaireActions.setNameStore(theNameStore))
        }

        dispatch(
          commonActions.setAvailableWorlds({
            answerCache: questionnaire.answerCache,
          }),
        )

        // Check for name from 'will' world first, then 'social' world
        const name =
          questionnaire?.answerCache?.firstName &&
          questionnaire?.answerCache?.lastName
            ? `${questionnaire.answerCache.firstName} ${questionnaire.answerCache.lastName}`
            : questionnaire?.answerCache?.social_firstName &&
                questionnaire?.answerCache?.social_lastName
              ? `${questionnaire.answerCache.social_firstName} ${questionnaire.answerCache.social_lastName}`
              : undefined

        // Identify Logrocket user by userId
        LogRocket.identify(_id, { name, email })

        // Boot Intercom with user info
        if (window.Intercom) {
          window.Intercom('boot', {
            name,
            email,
            user_id: _id,
            app_id: intercomAppId,
            province: questionnaire?.answerCache?.province,
            hasPartner: questionnaire?.answerCache?.hasPartner,
            hasChildren: questionnaire?.answerCache?.hasChildren,
            created_at: new Date(
              Number.parseInt(_id.toString().slice(0, 8), 16) * 1000,
            ),
          })
        }

        // Set user identity information in Amplitude
        amplitude.setUserId(email)
        amplitude.setUserProperties({
          email,
          abTest,
          user_id: _id,
          isPartner,
          isPaying: !_isEmpty(payments),
        })

        if (questionnaire?.referrer) {
          amplitude.setGroup('ref', questionnaire?.referrer?.ref || 'n/a')
          amplitude.setGroup('reftag', questionnaire?.referrer?.reftag || 'n/a')
        }
      }
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        onError: (resolvedErrorMessage) =>
          dispatch(commonActions.setGlobalErrorMessage(resolvedErrorMessage)),
      })
    } finally {
      dispatch(commonActions.authWorking(false))
    }
    done()
  },
})
