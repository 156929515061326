import React, { useState } from 'react'

import _fill from 'lodash/fill'

import { Box } from '@mui/material'

import { Document, pdfjs } from 'react-pdf'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../styles/muiTheme'
import Modal from '../atoms/Modal'
import PdfPage from './components/PdfPage'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

interface Props {
  file: any
  show: boolean
  onClose: () => void
  omitPageNumbers?: Array<number>
}

const MAX_WIDTH = 815

const PdfPreviewModal = ({
  file,
  show,
  onClose,
  omitPageNumbers = [],
}: Props) => {
  const [totalPages, setTotalPages] = useState<number>()

  return (
    <Modal
      fullBleed
      show={show}
      maxWidth="xl"
      onClose={onClose}
      contentWrapperDisplay="block"
      data-testid="pdf-preview-modal"
    >
      <Box
        sx={{
          pb: '11rem',
          backgroundColor:
            themeColors[themeColorTypes.GREY][themeColorVariants.LIGHTEST],
        }}
      >
        <Document
          file={file}
          loading="Loading..."
          onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
        >
          <>
            {React.Children.toArray(
              _fill(new Array(totalPages), '').map(
                (_, i) =>
                  // Only page numbers that are not to be omitted are rendered
                  !omitPageNumbers.includes(i + 1) && (
                    <Box sx={{ mb: '1rem' }}>
                      <PdfPage maxWidth={MAX_WIDTH} pageNum={i + 1} />
                    </Box>
                  ),
              ),
            )}
          </>
        </Document>
      </Box>
    </Modal>
  )
}

export default PdfPreviewModal
