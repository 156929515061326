//
// TODO: This whole component is a mess and needs to be re-thought/re-factored
//

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _last from 'lodash/last'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import cn from 'classnames'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../common/components/atoms/Button'
import LoadingSpinner from '../../../../../common/components/atoms/LoadingSpinner'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import navigationActions from '../../../ducks/navigation/navigationActions'
import navigationSelectors from '../../../ducks/navigation/navigationSelectors'
import validationActions from '../../../ducks/validation/validationActions'
import validationSelectors from '../../../ducks/validation/validationSelectors'

const useStyles = makeStyles({
  nextButton: {
    minWidth: '200px',
    padding: '0.52rem 2.4rem',

    '& span.MuiButton-endIcon': {
      willChange: 'transform',
      transform: 'translateX(0px)',
      transition: 'transform 0.22s',
    },

    '&:hover, &:focus, &:active': {
      '& span.MuiButton-endIcon': {
        transition: 'transform 0.22s',
        transform: ({ disabledStyle }) =>
          disabledStyle ? 'translateX(0px)' : 'translateX(3px)',
      },
    },
  },

  invalidNextButton: ({ disabledStyle }) => ({
    cursor: disabledStyle ? 'not-allowed' : 'pointer',

    '&:hover': {
      backgroundColor:
        disabledStyle &&
        themeColors[themeColorTypes.GREY][themeColorVariants.MAIN],
    },
  }),
})

const NavigationButtons = ({
  working,
  nextLabel,
  preventNext,
  nextDisabled = false,
  questionStack,
  invalidFields,
  hideNextButton,
  handleButtonClick,
  displayValidation,
  sandwichButtonData = {},
  dispatchNextQuestion,
  dispatchPreviousQuestion,
  dispatchDisplayValidation,
  editQuestionnaireFromDashboard,
}) => {
  const { t } = useTranslation()

  const theNextLabel = nextLabel || t('common:saveAndContinue')
  const { fields, optional, type } = _last(questionStack)

  const {
    icon: sandwichIcon,
    label: sandwichLabel,
    hidden: sandwichHidden,
    onClick: sandwichOnClick,
  } = sandwichButtonData

  const isInvalid = !_isEmpty(invalidFields) || preventNext

  const optionalCta =
    fields &&
    optional &&
    invalidFields &&
    /*
     * Some questions can have custom validation at the question level,
     * as opposed to standard valiation which validates at the field level. By
     * filtering out any invalidFields that contain '-QUESTION' we are filtering
     * out any custom question validation that is failing, because leaving them
     * in would create an unexpected experience when dealing with optional questions.
     */
    fields.length ===
      invalidFields.filter(
        (invalidField) => !invalidField.includes('-QUESTION'),
      ).length

  /*
   * if the question is optional, allow the user to move to the next question by sending
   * fragments with the question's field-names as properties and undefined as the values.
   * This allows the user to simultaneously move to the next question and clear any values
   * that were input before clicking the 'skip question' button. This clears both the
   * answerStore and answerCache of the values for any fields of this question.
   */
  const undefinedForAllQuestionFragments = () => {
    const obj = {}

    if (_isEmpty(fields)) {
      return obj
    }

    fields.forEach((field) => {
      obj[field.name] = undefined
    })

    return obj
  }

  useEffect(() => {
    /*
     * If an optional question is shown a validation error, but then
     * transitions back to a state where the optional CTA is displayed,
     * even if their are invalid fields we want to hide the error
     * validation because the question is technically in a valid state
     * and the user can move ahead if they want
     */
    if (displayValidation && optional) {
      dispatchDisplayValidation(!optionalCta)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionalCta])

  const disabledStyle = !optionalCta && (isInvalid || nextDisabled)

  const classes = useStyles({ disabledStyle })

  const navigate = useNavigate()

  return (
    <Box mt="1.8rem" display="flex" flexDirection="column" alignItems="center">
      {working ? (
        <LoadingSpinner />
      ) : (
        <>
          {!hideNextButton && (
            <Button
              size="lg"
              type="submit"
              variant="contained"
              iconFontSize="1.15rem"
              endIcon={<ArrowForwardIcon />}
              data-testid="primary-navigation-button"
              label={
                optionalCta
                  ? t('common:noThanks')
                  : t(_get(theNextLabel, 'tKey', theNextLabel))
              }
              className={cn(
                classes.nextButton,
                isInvalid && !optionalCta && classes.invalidNextButton,
              )}
              color={
                disabledStyle ? themeColorTypes.GREY : themeColorTypes.BRAND
              }
              onClick={() => {
                if (optionalCta) {
                  dispatchNextQuestion({
                    questionFragments: undefinedForAllQuestionFragments(),
                  })
                } else if (isInvalid || nextDisabled) {
                  if (!displayValidation) {
                    dispatchDisplayValidation(true)
                  }
                } else {
                  handleButtonClick()
                }
              }}
            />
          )}

          {editQuestionnaireFromDashboard && type === 'sectionReview' && (
            <Box mt="1.25rem">
              <Button
                size="sm"
                iconFontSize="1rem"
                variant="outlined"
                label={t('common:returnToSummary')}
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  if (isInvalid || nextDisabled) {
                    dispatchDisplayValidation(true)
                  } else {
                    handleButtonClick()
                    navigate('/dashboard/summary')
                    window.scrollTo(0, 0)
                  }
                }}
              />
            </Box>
          )}

          {!optionalCta && optional && (
            <motion.div
              initial={{ y: -34, opacity: 0, height: 0, overflow: 'hidden' }}
              animate={{ y: 0, opacity: 1, height: 'auto' }}
              className="mt-5 flex justify-center"
            >
              <Button
                size="xs"
                label={t('common:noThanks')}
                iconFontSize="1rem"
                endIcon={<ArrowForwardIcon />}
                color={themeColorTypes.GREY}
                data-testid="no-thanks-sandwich-button"
                colorVariant={themeColorVariants.DARK}
                onClick={() =>
                  dispatchNextQuestion({
                    questionFragments: undefinedForAllQuestionFragments(),
                  })
                }
              />
            </motion.div>
          )}

          {!sandwichHidden && sandwichLabel && sandwichOnClick && (
            <Box
              mt="0.9rem"
              px="0.5rem"
              py="0.25rem"
              mb="-0.75rem"
              display="flex"
              alignItems="center"
            >
              <Button
                size="xs"
                label={sandwichLabel}
                iconFontSize="1rem"
                endIcon={sandwichIcon}
                onClick={sandwichOnClick}
                data-testid="sandwich-button"
                color={themeColorTypes.GREY}
                colorVariant={themeColorVariants.DARK}
              />
            </Box>
          )}

          {/* Back Button */}
          {questionStack.length > 1 && (
            <Box mt="0.9rem" display="flex" justifyContent="center">
              <Button
                size="xs"
                label={t('common:back')}
                iconFontSize="1rem"
                data-testid="back-button"
                startIcon={<ArrowBackIcon />}
                color={themeColorTypes.GREY}
                colorVariant={themeColorVariants.DARK}
                onClick={dispatchPreviousQuestion}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

NavigationButtons.propTypes = {
  working: PropTypes.bool,
  nextLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ tKey: PropTypes.string }),
  ]),
  nextDisabled: PropTypes.bool,
  hideNextButton: PropTypes.bool,
  sandwichButtonData: PropTypes.shape({
    hidden: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  preventNext: PropTypes.bool.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  displayValidation: PropTypes.bool.isRequired,
  dispatchNextQuestion: PropTypes.func.isRequired,
  dispatchDisplayValidation: PropTypes.func.isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  dispatchPreviousQuestion: PropTypes.func.isRequired,
  editQuestionnaireFromDashboard: PropTypes.bool.isRequired,
  questionStack: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  displayValidation: validationSelectors.getDisplayValidation(
    state.questionnaire,
  ),
  preventNext: navigationSelectors.getPreventNext(state.questionnaire),
  questionStack: navigationSelectors.getQuestionStack(state.questionnaire),
  invalidFields: validationSelectors.getInvalidFields(state.questionnaire),
  editQuestionnaireFromDashboard:
    navigationSelectors.getEditQuestionnaireFromDashboard(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchNextQuestion: ({ questionFragments }) =>
    dispatch(navigationActions.nextQuestion({ questionFragments })),
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
  dispatchDisplayValidation: (bool) =>
    dispatch(validationActions.displayValidation(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavigationButtons)
