// TAG: notify-appointees
import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../common/components/atoms/Text'
import commonActions from '../../../../../../../common/ducks/commonActions'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import questionnaireSelectors from '../../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import { Appointee } from '../../../../../ducks/appointees/appointees.types'
import appointeesActions from '../../../../../ducks/appointees/appointeesActions'
import appointeesSelectors from '../../../../../ducks/appointees/appointeesSelectors'
import dashboardSelectors from '../../../../../ducks/dashboard/dashboardSelectors'
import NotifyAppointee from './components/NotifyAppointee'
import batchAppointees, { BatchedAppointee } from './utils/batchAppointees'

interface Props {
  answerStore: any
  appointees: Appointee[]
  dispatchGetAppointees: () => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
  customerReferralCode?: string
}

const NotifyAppointees = ({
  appointees,
  dispatchGetAppointees,
  dispatchSetGlobalErrorMessage,
  customerReferralCode = undefined,
  answerStore: { firstName: userFirstName, lastName: userLastName },
}: Props) => {
  useEffect(() => {
    dispatchGetAppointees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  const batchedAppointees = batchAppointees(appointees)

  return (
    <Box
      data-testid="notify-appointees-wrapper"
      sx={{
        minWidth: { min: 'unset', lg: '33rem' },
        maxWidth: { min: 'unset', lg: '35rem' },
        p: { min: '1.1rem 0 4rem', md: '1.1rem 0.3rem 4rem' },
      }}
    >
      {_isEmpty(appointees) ? (
        <Heading align="center" variant="h3">
          {t('documents:components.NotifyAppointees.label.empty')}
        </Heading>
      ) : (
        <>
          <Heading align="center" variant="h2">
            {t('documents:components.NotifyAppointees.label.default')}
          </Heading>

          <Text
            align="center"
            variant="paragraph"
            sx={{ mt: '0.9rem', mb: '2.4rem' }}
          >
            {t('documents:components.NotifyAppointees.subLabel')}
          </Text>

          {React.Children.toArray(
            batchedAppointees.map((appointee: BatchedAppointee, index) => (
              <Box
                sx={{
                  px: '1.3rem',
                  py: '0.35rem',
                  borderRadius: '9px',
                  mt: index !== 0 ? '0.8rem' : '0',
                  border: `1.5px solid ${
                    themeColors[themeColorTypes.ACCENT_2][
                      themeColorVariants.MAIN
                    ]
                  }`,
                }}
              >
                <NotifyAppointee
                  index={index}
                  appointee={appointee}
                  userLastName={userLastName}
                  userFirstName={userFirstName}
                  customerReferralCode={customerReferralCode}
                  dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
                />
              </Box>
            )),
          )}
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  appointees: appointeesSelectors.getAppointees(state.dashboard),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  customerReferralCode: dashboardSelectors.getCustomerReferralCode(
    state.dashboard,
  ),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetAppointees: () => dispatch(appointeesActions.getAppointees()),
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotifyAppointees)
