import React, { useEffect } from 'react'

import { Box } from '@mui/material'

import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import growAnimation from '../../../../../../../common/animations/growAnimation'
import { ReactComponent as DocumentsSvg } from '../../../../../../../common/assets/images/documents.svg'
import { ReactComponent as SuccessCheck } from '../../../../../../../common/assets/images/success-check.svg'
import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../../common/lib/amplitude'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import useEmailDocuments from '../../hooks/useEmailDocuments'

interface Props {
  show: boolean
  userId: string
  answerStore: any
  onClose: () => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const EmailDocumentsModal = ({
  show,
  userId,
  onClose,
  answerStore,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const { EmailStatusTypes, status, setStatus, handleSubmit } =
    useEmailDocuments({
      userId,
      answerStore,
      dispatchSetGlobalErrorMessage,
    })

  const handleOnClose = () => {
    if (status !== EmailStatusTypes.IDLE) {
      setStatus(EmailStatusTypes.IDLE)
    }
    if (show) amplitude.sendEvent('ClosedEmailDocumentsModal')
    onClose()
  }

  useEffect(() => {
    if (show) amplitude.sendEvent('ViewedEmailDocumentsModal')
  }, [show])

  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      maxWidth="lg"
      onClose={handleOnClose}
      preventClose={status === 'working'}
      data-testid="email-documents-modal"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          p: {
            xss: status === 'success' ? '1rem' : '0',
            md: status === 'success' ? '2rem' : '2rem',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {status === 'success' ? (
            <motion.div {...growAnimation}>
              <Box width="52px" display="flex" justifyContent="center">
                <SuccessCheck />
              </Box>
            </motion.div>
          ) : (
            <Box sx={{ width: '115px' }}>
              <DocumentsSvg />
            </Box>
          )}

          <Heading
            variant="h4"
            align="center"
            sx={{
              mb: '0.5rem',
              mt: status === 'success' ? '0.1rem' : '1.7rem',
            }}
          >
            {status === 'success'
              ? t(
                  'documents:components.modals.EmailDocumentsModal.label.success',
                )
              : t(
                  'documents:components.modals.EmailDocumentsModal.label.default',
                  { email: answerStore.email },
                )}
          </Heading>

          {status !== 'success' && (
            <>
              <Text
                size="sm"
                align="center"
                variant="paragraph"
                sx={{ mb: '2rem' }}
              >
                {t('documents:components.modals.EmailDocumentsModal.subLabel')}
              </Text>

              <Button
                size="sm"
                variant="contained"
                onClick={handleSubmit}
                label={t(
                  'documents:components.modals.EmailDocumentsModal.button1Label',
                )}
                working={status === EmailStatusTypes.WORKING}
                color={themeColorTypes.ACCENT_2}
                spinnerPadding="0.3205rem 3.829rem"
                spinnerColor={themeColorTypes.GREY}
                data-testid="email-documents-send-button"
              />

              <Button
                size="xs"
                label={t('common:cancel')}
                sx={{ mt: '0.5rem' }}
                onClick={handleOnClose}
                color={themeColorTypes.GREY}
                disabled={status === 'working'}
                colorVariant={themeColorVariants.DARKER}
                data-testid="email-documents-send-cancel-button"
              />
            </>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default EmailDocumentsModal
