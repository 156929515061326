import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'

import { AnimatePresence, motion } from 'framer-motion'
import { Button, Text } from 'grommet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import listItemAnimation from '../../../../../common/animations/listItemAnimation'
import CharityFinder from '../../../../../common/components/CharityFinder'
import listQuestionReducer from '../../../utils/listQuestionReducer'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'
import Charity from './components/Charity'
import MixedCharitableGiftsModal from './components/MixedCharitableGiftsModal'

const StyledButton = styled(Button)`
  height: 38px;
`
const newCharity = () => ({
  type: 'charity',
  relationship: 'charitableOrg',
  id: Date.now().toString(),
})

const CharitableGiftDetails = ({
  question,
  answerStore,
  answerCache,
  displayValidation,
  handleFieldFragmentUpdate,
  invalidFields = [],
}) => {
  /*
   * 'Belts and suspenders' to ensure that the initialState of this question,
   * when pre-populated from the answerCache, never pre-populates percentage
   * gifts from the answerCache when hasChildren is 'no'.
   *
   * DO NOT REMOVE. This solves a remote but re-creatable UI bug.
   */
  const transformedAnswerCacheCharitableGiftDetails =
    answerStore.hasChildren === 'no'
      ? answerCache.charitableGiftDetails?.filter(
          (giftDetail) => !giftDetail.isPercent,
        )
      : answerCache.charitableGiftDetails

  const [field] = question.fields

  const initialState = transformedAnswerCacheCharitableGiftDetails || []

  const [displayCharityFinder, setDisplayCharityFinder] = useState(false)
  const [charities, dispatch] = useReducer(listQuestionReducer, initialState)

  const hasMixedGifts =
    charities.some((charity) => charity.isPercent) &&
    charities.some((charity) => !charity.isPercent) &&
    charities.every((charity) => charity.amount)

  const [mixedGiftsModalVisible, setMixedGiftsModalVisible] = useState(false)
  const [mixedGiftsModalViewed, setMixedGiftsModalViewed] =
    useState(hasMixedGifts)

  const percentExceedsMaximum =
    !invalidFields.includes(field.name) &&
    invalidFields.includes(`${field.name}-QUESTION`)

  useEffect(
    () => {
      handleFieldFragmentUpdate({ [field.name]: charities })

      /*
       * When there is at least 1 type of each charitable gift (dollar and percent)
       * and the user has not seen the modal yet, we display the mixed-gifts modal.
       */
      if (!mixedGiftsModalViewed && hasMixedGifts) {
        setMixedGiftsModalVisible(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [charities],
  )

  const { t } = useTranslation()

  return (
    <>
      <CharityFinder
        show={displayCharityFinder}
        setShow={setDisplayCharityFinder}
        handleAddCharity={({ name, number, location }) => {
          setDisplayCharityFinder(false)
          dispatch({
            type: 'ADD',
            payload: {
              ...newCharity(),
              name,
              number,
              location,
            },
          })
        }}
      />
      <div
        className="max-w-sm w-full"
        data-testid="charitableGiftDetails-question-wrapper"
      >
        <div className="mb-3 flex justify-center">
          <ValidationMessage
            question={question}
            field={field}
            message={
              percentExceedsMaximum
                ? t('validation:validation.donationsNotExceed100')
                : t('validation:validation.allFieldsRequired')
            }
          />
        </div>

        <AnimatePresence initial={false}>
          {charities?.map((charity) => (
            <motion.div key={charity.id} {...listItemAnimation()}>
              <Charity
                charity={charity}
                displayValidation={displayValidation}
                percentExceedsMaximum={percentExceedsMaximum}
                update={(updatedCharity) =>
                  dispatch({ type: 'UPDATE', payload: updatedCharity })
                }
                remove={(removedCharityId) =>
                  dispatch({ type: 'REMOVE', payload: removedCharityId })
                }
              />
            </motion.div>
          ))}
        </AnimatePresence>

        <div className="w-full flex justify-center mt-10 mb-8">
          <StyledButton
            primary
            color="neutral-3"
            data-testid="add-another-charity-button"
            onClick={() => setDisplayCharityFinder(true)}
            label={
              <div className="text-brand">
                <Text size="small" weight="bold">
                  <span className="mr-1">+</span>{' '}
                  {charities.length > 1
                    ? t(
                        'customQuestion:CharitableGiftDetails.addAnotherCharity',
                      )
                    : t('customQuestion:CharitableGiftDetails.addACharity')}
                </Text>
              </div>
            }
          />
        </div>

        <MixedCharitableGiftsModal
          show={mixedGiftsModalVisible}
          onClose={() => {
            setMixedGiftsModalViewed(true)
            setMixedGiftsModalVisible(false)
          }}
        />
      </div>
    </>
  )
}

CharitableGiftDetails.propTypes = {
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  answerCache: PropTypes.shape({
    charitableGiftDetails: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  displayValidation: PropTypes.bool.isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  answerStore: PropTypes.shape({ hasChildren: PropTypes.string }).isRequired,
}

export default CharitableGiftDetails
