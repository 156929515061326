import _cloneDeep from 'lodash/cloneDeep'
import _has from 'lodash/has'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'

import mingo from 'mingo'
import { createLogic } from 'redux-logic'

import commonSelectors from '../../../../../common/ducks/commonSelectors'
import dashboardSelectors from '../../../../Dashboard/ducks/dashboard/dashboardSelectors'
import documentSelectors from '../../../../Dashboard/ducks/documents/documentsSelectors'
import data from '../../../data'
import { cleansedAnswerStore, nameStore } from '../../../engine'
import navigationActions from '../../navigation/navigationActions'
import navigationSelectors from '../../navigation/navigationSelectors'
import questionnaireActions from '../questionnaireActions'
import questionnaireName from '../questionnaireName'
import questionnaireSelectors from '../questionnaireSelectors'
import questionnaireTypes from '../questionnaireTypes'
import capitalCaseAnswerStoreNames from '../utils/capitalCaseAnswerStoreNames'

export default createLogic({
  name: 'updateAnswerStore',
  type: questionnaireTypes.UPDATE_ANSWER_STORE,
  transform({ getState, action }, next) {
    const state = getState()

    const currentQuestion = navigationSelectors.getCurrentQuestion(
      state.questionnaire,
    )

    const currentAnswerStore = questionnaireSelectors.getAnswerStore(
      state.questionnaire,
    )

    const referrer = questionnaireSelectors.getReferrer(
      getState().questionnaire,
    )
    const abTest = questionnaireSelectors.getAbTest(state.questionnaire)
    const documents = documentSelectors.getDocuments(state.dashboard)

    const { answerStoreFragment } = action.payload

    // Do NOT change the default value of [] for sideEffects
    const { sideEffects = [] } = currentQuestion
    /*
     * Builds an object of question fragments based on triggers
     * and their corresponding fragments from the sideEffects array.
     */
    const combinedFragments = sideEffects.reduce((accumulator, sideEffect) => {
      const combinedAnswerStore = {
        ...currentAnswerStore,
        ...answerStoreFragment,
      }
      /*
       * If a trigger returns true, we add the corresponding sideEffects'
       * question fragment to the answerStoreFragment object.
       * If the trigger returns false, we return the accumulation of the
       * answerStoreFragment and any triggered sideEffect question fragments
       * that have come before.
       */
      const isTriggered = new mingo.Query(sideEffect.trigger).test(
        combinedAnswerStore,
      )
      /*
       * The value type of sideEffect.questionFragments can either
       * be an object OR a function that returns an object. If it's
       * a function, we want to pass in the answerStore as the argument
       * to the function, because this is what's expected in the 'JSON', and
       * allows us to use values from the answerStore in the 'JSON'.
       */
      const resolvedQuestionFragments = _isFunction(
        sideEffect.questionFragments,
      )
        ? sideEffect.questionFragments({ answerStore: currentAnswerStore })
        : sideEffect.questionFragments

      return isTriggered
        ? { ...accumulator, ...resolvedQuestionFragments }
        : accumulator
    }, answerStoreFragment)

    const answerStoreWithNewFragments = {
      ...currentAnswerStore,
      ...capitalCaseAnswerStoreNames(combinedFragments), // incoming fragment
    }

    const charityPartner = commonSelectors.getCharityPartner(state)

    // remove all untriggered answers from the answerStore
    const theCleansedAnswerStore = cleansedAnswerStore({
      data,
      abTest,
      referrer,
      documents,
      charityPartner,
      prevAnswerStore: currentAnswerStore,
      currentAnswerStore: answerStoreWithNewFragments,
    })

    // send Intercom update for name or date-of-birth
    if (
      window.Intercom &&
      (_has(combinedFragments, 'firstName') ||
        _has(combinedFragments, 'lastName') ||
        _has(combinedFragments, 'social_firstName') ||
        _has(combinedFragments, 'social_lastName') ||
        _has(combinedFragments, 'dateOfBirth'))
    ) {
      // Check for name from 'will' world first, then 'social' world
      const name =
        _has(theCleansedAnswerStore, ['firstName']) &&
        _has(theCleansedAnswerStore, ['lastName'])
          ? `${theCleansedAnswerStore.firstName} ${theCleansedAnswerStore.lastName}`
          : _has(theCleansedAnswerStore, ['social_firstName']) &&
              _has(theCleansedAnswerStore, ['social_lastName'])
            ? `${theCleansedAnswerStore.social_firstName} ${theCleansedAnswerStore.social_lastName}`
            : undefined

      window.Intercom('update', {
        name,
        date_of_birth: theCleansedAnswerStore.dateOfBirth,
      })
    }

    const clonedAction = _cloneDeep(action)
    clonedAction.payload.answerStoreFragment = combinedFragments
    clonedAction.payload.cleansedAnswerStore = theCleansedAnswerStore

    next(clonedAction)
  },
  process({ getState, action }, dispatch, done) {
    const currentAnswerStore =
      getState().questionnaire[questionnaireName].answerStore
    const isPartner = dashboardSelectors.getIsPartner(getState().dashboard)

    const prevNameStore = questionnaireSelectors.getNameStore(
      getState().questionnaire,
    )
    const referrer = questionnaireSelectors.getReferrer(
      getState().questionnaire,
    )
    const charityPartner = commonSelectors.getCharityPartner(getState())
    const abTest = questionnaireSelectors.getAbTest(getState().questionnaire)
    const documents = documentSelectors.getDocuments(getState().dashboard)

    const currentNameStore = nameStore({
      data,
      abTest,
      referrer,
      isPartner,
      documents,
      charityPartner,
      answerStore: currentAnswerStore,
    })

    // only update name store if prev is different than current
    if (!_isEqual(prevNameStore, currentNameStore)) {
      dispatch(questionnaireActions.setNameStore(currentNameStore))
    }

    dispatch(questionnaireActions.updateQuestionnaire())

    // after update, it is safe to clear fragments
    dispatch(questionnaireActions.clearQuestionFragments())

    /*
     * When core answerStore items are being upserted via a bonusDoc questionnaire
     * we want to "update" the question stack with a clone of the current question
     * stack so that we 'kick off' the process of checking whether the incoming
     * answerStore fragments changed the core questionnaire from being invalid
     * to valid, and then update the core progress bar. Without this, the
     * fragments coming from the bonusDoc questionnaire wouldn't affect the
     * core questionnaire's progress or 'completion', when it should.
     */
    if (action.payload.fromBonusDocQuestionnaire) {
      const questionStack = navigationSelectors.getQuestionStack(
        getState().questionnaire,
      )

      dispatch(navigationActions.updateQuestionStack({ questionStack }))
    }
    done()
  },
})
