import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'

import { CONSTANTS } from '@epilogue/common'
import { createLogic } from 'redux-logic'

import commonActions from '../../../../../common/ducks/commonActions'
import commonSelectors from '../../../../../common/ducks/commonSelectors'
import queryString from '../../../../../common/lib/queryString'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import { getReferrerFromCookies } from '../../../../../common/utils/referrer'
import { getUtms } from '../../../../../common/utils/utms'
import documentsSelectors from '../../../../Dashboard/ducks/documents/documentsSelectors'
import data from '../../../data'
import { nextUnansweredQuestion } from '../../../engine'
import navigationActions from '../../navigation/navigationActions'
import questionnaireActions from '../questionnaireActions'
import questionnaireTypes from '../questionnaireTypes'

export default createLogic({
  name: 'createQuestionnaire',
  type: questionnaireTypes.CREATE_QUESTIONNAIRE,
  async process({ getState }, dispatch, done) {
    const state = getState()

    const charityPartner = commonSelectors.getCharityPartner(state)
    const documents = documentsSelectors.getDocuments(state.dashboard)

    let ref
    let reftag
    const referrer = getReferrerFromCookies()
    const utms = getUtms()

    if (referrer?.ref) {
      ref = referrer.ref
      reftag = referrer.reftag
    }

    const parsed = queryString.parse(window.location.search)
    const initialAnswers = _get(parsed, 'answer', {})

    try {
      // create a new questionnaire on the backend
      const res = await request({
        url: PATHS.QUESTIONNAIRE,
        method: 'POST',
        data: { ref, reftag, ...(_isEmpty(utms) ? {} : { utms }) },
      })

      const initialQuestionnaireProperties = {
        answerCache: initialAnswers,
        answerStore: initialAnswers,
        ...res.data.payload,
      }

      // set accepted properties that are returned from the backend in the Redux store
      dispatch(
        questionnaireActions.setQuestionnaireProperties(
          _pick(
            initialQuestionnaireProperties,
            CONSTANTS.acceptedIncomingQuestionnaireProperties,
          ),
        ),
      )

      /*
       * payments, isPartner, and accountCreated are hard-coded to be false
       * or empty because any user with a different value for any of those
       * properties wouldn't be creating a new questionnaire.
       */
      const theNextUnansweredQuestion = nextUnansweredQuestion({
        data,
        referrer,
        documents,
        payments: [],
        charityPartner,
        isPartner: false,
        accountCreated: false,
        answerStore: initialAnswers,
        abTest: _get(initialQuestionnaireProperties, 'abTest'),
      })

      dispatch(
        navigationActions.toQuestion({ question: theNextUnansweredQuestion }),
      )
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to create your questionnaire. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage) =>
          dispatch(commonActions.setGlobalErrorMessage(resolvedErrorMessage)),
      })
    }

    done()
  },
})
