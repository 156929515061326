import React, { useState } from 'react'

import _uniqBy from 'lodash/uniqBy'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'

import { Timezone } from '@epilogue/common'
import { format, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../styles/muiTheme'
import Button from '../atoms/Button'
import { SelectedTimeslot, Timeslot } from '../DatePicker/datePicker.types'

interface Props {
  timezone: Timezone
  timeslots: Timeslot[]
  onSelect: (selectedTimeslot: SelectedTimeslot) => void
}

const halfButtonSize = '170px'
const animationDuration = 0.285

const TimePicker = ({ timezone, onSelect, timeslots }: Props) => {
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot>()

  const handleSelectTime = () => {
    if (selectedTimeslot) {
      const isoTimeslot: Pick<Timeslot, 'start' | 'end'> = {
        start: format(
          utcToZonedTime(parseISO(selectedTimeslot.start), 'UTC'),
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
        ),
        end: format(
          utcToZonedTime(parseISO(selectedTimeslot.end), 'UTC'),
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
        ),
      }

      onSelect({
        timezone,
        isoTimeslot,
        timezoneAdjustedTimeslot: selectedTimeslot,
      })
    }
  }

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        px: '1rem',
        pb: '6rem',
        pt: '0.75rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {_uniqBy(timeslots, 'start').map((timeslot) => {
        const { start } = timeslot
        const isSelected = start === selectedTimeslot?.start
        const formattedStartTime = format(new Date(start), 'h:mmaaa')

        return (
          <Box
            key={start}
            sx={{
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              minWidth: `calc(${halfButtonSize} * 2)`,
            }}
          >
            <Box
              variants={{
                shrink: {
                  width: halfButtonSize,
                  transition: {
                    ease: 'easeInOut',
                    duration: animationDuration,
                  },
                },
                grow: {
                  width: '100%',
                  transition: {
                    ease: 'easeInOut',
                    duration: animationDuration,
                  },
                },
              }}
              initial="grow"
              component={motion.div}
              animate={isSelected ? 'shrink' : 'grow'}
            >
              <Button
                size="lg"
                fullWidth
                key={start}
                variant="outlined"
                label={formattedStartTime}
                data-testid="timestart-button"
                onClick={() => setSelectedTimeslot(timeslot)}
                sx={{
                  my: { min: '0.9rem', md: '0.65rem' },
                  color: isSelected ? 'white !important' : undefined,
                  backgroundColor: isSelected
                    ? `${
                        themeColors[themeColorTypes.BRAND][
                          themeColorVariants.MAIN
                        ]
                      } !important`
                    : undefined,
                }}
              />
            </Box>

            <AnimatePresence>
              {isSelected && (
                <Box
                  component={motion.div}
                  sx={{ width: halfButtonSize, ml: '0.6rem' }}
                  initial={{
                    opacity: 0,
                    position: 'absolute',
                    x: `calc(${halfButtonSize} * 2)`,
                  }}
                  animate={{
                    opacity: 1,
                    x: halfButtonSize,
                    transition: {
                      x: {
                        ease: 'easeInOut',
                        duration: animationDuration,
                      },
                      opacity: {
                        ease: 'easeInOut',
                        duration: animationDuration,
                      },
                    },
                  }}
                  exit={{ display: 'none' }}
                >
                  <Button
                    fullWidth
                    label={t('common:continue')}
                    variant="outlined"
                    onClick={handleSelectTime}
                    endIcon={<ArrowForwardIcon />}
                    color={themeColorTypes.ACCENT_1}
                    data-testid="timestart-confirm-button"
                    colorVariant={themeColorVariants.DARKER}
                  />
                </Box>
              )}
            </AnimatePresence>
          </Box>
        )
      })}
    </Box>
  )
}

export default TimePicker
