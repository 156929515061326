import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion,
} from '@mui/material'

import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'
import Heading from '../Heading'

const Accordion = ({
  onClick,
  children,
  defaultExpanded = false,
  'data-testid': dataTestId,
  dividerColorData: { color: dividerColor, colorVariant: dividerColorVariant },
  expandIconColorData: {
    color: expandIconColor,
    colorVariant: expandIconColorVariant,
  },
  headingData: {
    label: headingLabel,
    color: headingColor,
    variant: headingVariant,
    colorVariant: headingColorVariant,
  },
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <MuiAccordion
      square
      elevation={0}
      disableGutters
      data-testid={dataTestId}
      defaultExpanded={defaultExpanded}
      sx={{
        backgroundColor: 'transparent',
        borderBottom: (theme) =>
          `solid 1px ${theme.palette[dividerColor][dividerColorVariant]}`,
        '&:before': {
          display: 'none',
        },
        '&:last-child': {
          borderBottom: '0',
        },
      }}
      onClick={() => {
        setIsOpen((prev) => !prev)
        onClick?.(isOpen)
      }}
    >
      <AccordionSummary
        sx={{
          p: 0,
          my: '0.15rem',
          width: '100%',
          '.MuiAccordionSummary-content': { width: '95%', pr: '0.8rem' },
          '.MuiAccordionSummary-expandIconWrapper': {
            width: '5%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
        expandIcon={
          <ExpandMoreIcon
            sx={{
              fontSize: '2rem',
              color: (theme) =>
                theme.palette[expandIconColor][expandIconColorVariant],
            }}
          />
        }
      >
        <Heading
          color={headingColor}
          sx={{ width: '100%' }}
          variant={headingVariant || 'h5'}
          colorVariant={headingColorVariant}
        >
          {headingLabel}
        </Heading>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
    </MuiAccordion>
  )
}

Accordion.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
  expandIconColorData: PropTypes.shape({
    color: PropTypes.oneOf(Object.values(themeColorTypes)).isRequired,
    colorVariant: PropTypes.oneOf(Object.values(themeColorVariants)).isRequired,
  }).isRequired,
  dividerColorData: PropTypes.shape({
    color: PropTypes.oneOf(Object.values(themeColorTypes)).isRequired,
    colorVariant: PropTypes.oneOf(Object.values(themeColorVariants)).isRequired,
  }).isRequired,
  headingData: PropTypes.shape({
    color: PropTypes.oneOf(Object.values(themeColorTypes)),
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.shape({
        tKey: PropTypes.string,
      }),
    ]),
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    colorVariant: PropTypes.oneOf(Object.values(themeColorVariants)),
  }).isRequired,
  'data-testid': PropTypes.string,
}

export default Accordion
