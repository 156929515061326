import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, styled } from '@mui/material'

import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import growAnimation from '../../../../../common/animations/growAnimation'
import { ReactComponent as SuccessCheck } from '../../../../../common/assets/images/success-check.svg'
import Button from '../../../../../common/components/atoms/Button'
import Checkbox from '../../../../../common/components/atoms/Checkbox'
import Heading from '../../../../../common/components/atoms/Heading'
import StyledLabel from '../../../../../common/components/atoms/StyledLabel'
import Text from '../../../../../common/components/atoms/Text'
import commonActions from '../../../../../common/ducks/commonActions'
import amplitude from '../../../../../common/lib/amplitude'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import { themeColorTypes } from '../../../../../common/styles/muiTheme'
import navigationActions from '../../../ducks/navigation/navigationActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'

const StyledSuccessCheck = styled(SuccessCheck)({
  width: '37px',
  height: '37px',
  margin: '1rem auto',
})

const PartnerDropOff = ({
  answerStore,
  dispatchPreviousQuestion,
  dispatchSetGlobalErrorMessage,
}) => {
  const { partnerFirstName } = answerStore

  const [isWorking, setIsWorking] = useState(false)
  const [hasEmailSent, setHasEmailSent] = useState(false)
  const [isAgreeChecked, setIsAgreeChecked] = useState(false)

  const onSendClick = async () => {
    try {
      setIsWorking(true)
      await request({
        url: PATHS.NOTIFY_PARTNER_SPOUSAL_TRUST,
        method: 'post',
      })
      amplitude.sendEvent('SubmittedPartnerDropOffRequest')
      setHasEmailSent(true)
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to send email. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    } finally {
      setIsWorking(false)
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
      >
        <Box sx={{ pb: '1.3rem' }}>
          <StyledLabel>{t('customQuestion:PartnerDropOff.label')}</StyledLabel>
        </Box>

        <Box sx={{ pb: '0.6rem' }}>
          <Text variant="paragraph">
            {t('customQuestion:PartnerDropOff.subLabel1')}
          </Text>
        </Box>

        <Box sx={{ pb: '2.9rem' }}>
          <Text variant="paragraph">
            {t('customQuestion:PartnerDropOff.subLabel2', { partnerFirstName })}
          </Text>
        </Box>
      </Box>

      <Box
        sx={{
          rowGap: '2rem',
          display: 'flex',
          maxWidth: '24rem',
          flexDirection: 'column',
        }}
      >
        {!hasEmailSent ? (
          <>
            <Checkbox
              checked={isAgreeChecked}
              data-testid="confirmation-checkbox"
              onChange={() => setIsAgreeChecked((prev) => !prev)}
              label={t('customQuestion:PartnerDropOff.checkboxLabel')}
            />
            <Box
              sx={{
                width: '100%',
                maxWidth: '12rem',
                alignSelf: 'center',
              }}
            >
              <Button
                fullWidth
                variant="contained"
                working={isWorking}
                spinnerSize="1.25rem"
                onClick={onSendClick}
                data-testid="confirm-send-button"
                spinnerColor={themeColorTypes.WHITE}
                disabled={!isAgreeChecked}
                label={t('common:continue')}
              />
            </Box>
          </>
        ) : (
          <>
            <motion.div data-testid="success-animation" {...growAnimation}>
              <StyledSuccessCheck />
            </motion.div>

            <Heading variant="h4" align="center">
              {t('customQuestion:PartnerDropOff.successLabel')}
            </Heading>
          </>
        )}
        <Box sx={{ alignSelf: 'center' }}>
          <Button
            size="xxs"
            label={t('common:back')}
            align="center"
            startIcon={<ArrowBackIcon />}
            data-testid="lower-back-button"
            onClick={dispatchPreviousQuestion}
          />
        </Box>
      </Box>
    </>
  )
}

PartnerDropOff.propTypes = {
  dispatchPreviousQuestion: PropTypes.func.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  answerStore: PropTypes.shape({
    partnerFirstName: PropTypes.string.isRequired,
  }).isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDropOff)
