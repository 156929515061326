import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import _isEmpty from 'lodash/isEmpty'

import { Button, Heading, Paragraph, Text } from 'grommet'
import { FormNextLink } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import Modal from '../../../../../../common/components/atoms/Modal'
import data from '../../../../../Questionnaire/data'
import navigationActions from '../../../../../Questionnaire/ducks/navigation/navigationActions'

const UpdateDocumentsModal = ({ show, handleSetShow, dispatchToQuestion }) => {
  const [returnQuestion, setReturnQuestion] = useState()

  useEffect(() => {
    if (!_isEmpty(data[0].questions)) {
      // The first question that gets asked in the questionnaire
      setReturnQuestion(data[0].questions[0])
    }
  }, [])

  const navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      onClose={() => {
        handleSetShow(false)
      }}
    >
      <div
        data-testid="update-documents-modal"
        className="p-5 py-10 sm:p-12 flex flex-col items-center max-w-3xl m-auto"
      >
        <div className="mb-3 text-center">
          <Heading level={4} size="smedium">
            {t('dashboard:components.UpdateDocumentsModal.label')}
          </Heading>
          {/* eslint-enable */}
        </div>

        <div className="text-center mb-4 px-1">
          <Paragraph size="smedium">
            {t('dashboard:components.UpdateDocumentsModal.subLabel')}
          </Paragraph>
        </div>

        <div className="mt-8 mb-4">
          <Button
            data-testid="update-documents-modal-cta-button"
            primary
            reverse
            label={
              <div className="py-1 px-1">
                <Text size="smedium" color="white">
                  {t('dashboard:components.UpdateDocumentsModal.button1Label')}
                </Text>
              </div>
            }
            icon={<FormNextLink className="-ml-2" color="white" />}
            onClick={() => {
              if (returnQuestion) {
                // update questionStack
                dispatchToQuestion(returnQuestion)

                // send user to questionnaire
                navigate('/questionnaire')
              }
            }}
          />
        </div>

        <Link to="/dashboard/summary" onClick={() => handleSetShow(false)}>
          <Button
            plain
            hoverIndicator
            label={
              <Text size="small" color="grey-5" className="px-2 py-1">
                {t('dashboard:components.UpdateDocumentsModal.button2Label')}
              </Text>
            }
          />
        </Link>
      </div>
    </Modal>
  )
}

UpdateDocumentsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleSetShow: PropTypes.func.isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchToQuestion: (question) =>
    dispatch(navigationActions.toQuestion({ question, fromDashboard: true })),
})

export default connect(null, mapDispatchToProps)(UpdateDocumentsModal)
