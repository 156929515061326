// TAG: notify-appointees
import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Box, Collapse, Fade, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Accordion from '../../../../../../../../common/components/atoms/Accordion'
import Button from '../../../../../../../../common/components/atoms/Button'
import Checkbox from '../../../../../../../../common/components/atoms/Checkbox'
import Heading from '../../../../../../../../common/components/atoms/Heading'
import TextInput from '../../../../../../../../common/components/atoms/TextInput'
import HighlightPill from '../../../../../../../../common/components/molecules/HighlightPill'
import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../common/request'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'
import { emailRegex } from '../../../../../../../Questionnaire/utils/validation/patterns'
import NotifyAppointeePreview from '../templates/NotifyAppointeePreview'
import { BatchedAppointee } from '../utils/batchAppointees'

interface Props {
  index: number
  userLastName: string
  userFirstName: string
  appointee: BatchedAppointee
  dispatchSetGlobalErrorMessage: (msg: string) => void
  customerReferralCode?: string
}

const NotifyAppointee = ({
  index,
  appointee,
  userLastName,
  userFirstName,
  dispatchSetGlobalErrorMessage,
  customerReferralCode = undefined,
}: Props) => {
  const { t } = useTranslation()

  const [email, setEmail] = React.useState<string>('')
  const [sent, setSent] = React.useState<boolean>(false)
  const [working, setWorking] = React.useState<boolean>(false)
  const [showPreview, setShowPreview] = React.useState<boolean>(false)
  const [copyUserCheckValue, setCopyUserCheckValue] =
    React.useState<boolean>(false)

  const handleSendEmail = async (e: React.FormEvent) => {
    e.preventDefault()
    setWorking(true)

    try {
      await request({
        method: 'POST',
        url: PATHS.APPOINTEE_SEND_EMAIL,
        data: {
          email,
          label: appointee.roles,
          copyUser: copyUserCheckValue,
        },
      })
      setSent(true)
      setEmail('')
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to send email. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    } finally {
      setWorking(false)
    }
  }

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  return (
    <Accordion
      data-testid="notify-appointee-accordion"
      defaultExpanded={index === 0}
      expandIconColorData={{
        color: themeColorTypes.BRAND,
        colorVariant: themeColorVariants.LIGHT,
      }}
      dividerColorData={{
        color: themeColorTypes.GREY,
        colorVariant: themeColorVariants.LIGHTER,
      }}
      headingData={{
        variant: 'h3',
        label: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Heading
                variant="h5"
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                  fontSize: '1.1rem',
                  whiteSpace: 'nowrap',
                  letterSpacing: '-0.4px',
                  textOverflow: 'ellipsis',
                }}
              >
                {appointee.name}
              </Heading>

              <Heading
                variant="h6"
                sx={{
                  width: '100%',
                  fontSize: '0.88rem',
                  letterSpacing: '-0.4px',
                }}
                color={themeColorTypes.GREY}
              >
                {appointee.roles}
              </Heading>

              {sent && (
                <Fade in={sent} timeout={{ enter: 450 }}>
                  <Box
                    sx={{
                      mt: '0.3rem',
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: 'fit-content',
                    }}
                  >
                    <HighlightPill
                      label={t(
                        'documents:components.NotifyAppointees.components.NotifyAppointee.highlightPillLabel',
                      )}
                    />
                  </Box>
                </Fade>
              )}
            </Box>
          </Box>
        ),
        color: themeColorTypes.BRAND,
        colorVariant: themeColorVariants.MAIN,
      }}
    >
      <Box
        component="form"
        onSubmit={handleSendEmail}
        sx={{ mt: '0.35rem', mb: '1.5rem' }}
      >
        <TextInput
          type="email"
          value={email}
          variant="filled"
          sx={{ mb: '-0.6rem' }}
          onInputChange={setEmail}
          data-testid="notify-appointee-email-input"
          placeholder={t(
            'documents:components.NotifyAppointees.components.NotifyAppointee.inputPlaceholder',
            { appointeeName: appointee.name },
          )}
        />

        <Box
          sx={{
            mt: isMobile ? '1.6rem' : '1.2rem',
            mb: isMobile ? '1.2rem' : '0.8rem',
          }}
        >
          <Checkbox
            size="small"
            labelSize="sm"
            checked={copyUserCheckValue}
            onChange={setCopyUserCheckValue}
            data-testid="notify-appointee-checkbox"
            label={
              <Heading
                variant="h6"
                sx={{
                  fontSize: '0.88rem',
                  letterSpacing: '-0.3px',
                  lineHeight: '1.25',
                }}
              >
                {t(
                  'documents:components.NotifyAppointees.components.NotifyAppointee.checkboxLabel',
                  { appointeeName: appointee.name },
                )}
              </Heading>
            }
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
          }}
        >
          <Button
            size="sm"
            type="submit"
            working={working}
            label={t(
              'documents:components.NotifyAppointees.components.NotifyAppointee.button1Label',
            )}
            variant="contained"
            disabled={!emailRegex.test(email)}
            spinnerPadding="0.3205rem 1.843rem"
            sx={{
              mr: isMobile ? '0' : '1rem',
              mb: isMobile ? '0.9rem' : '0',
            }}
          />

          <Button
            size="sm"
            data-testid="preview-email-button"
            onClick={() => setShowPreview(!showPreview)}
            label={
              showPreview
                ? t(
                    'documents:components.NotifyAppointees.components.NotifyAppointee.button2Label.case1',
                  )
                : t(
                    'documents:components.NotifyAppointees.components.NotifyAppointee.button2Label.case2',
                  )
            }
            startIcon={
              showPreview ? (
                <CloseIcon sx={{ mr: '-0.2rem' }} />
              ) : (
                <VisibilityOutlinedIcon sx={{ mr: '-0.2rem' }} />
              )
            }
          />
        </Box>

        <Collapse
          in={showPreview}
          timeout={{ enter: 700, exit: 400 }}
          data-testid="notify-appointee-preview-collapse-wrapper"
        >
          <Box sx={{ mt: '1.3rem' }}>
            <NotifyAppointeePreview
              userLastName={userLastName}
              userFirstName={userFirstName}
              appointeeRoles={appointee.roles}
              customerReferralCode={customerReferralCode}
            />
          </Box>
        </Collapse>
      </Box>
    </Accordion>
  )
}

export default NotifyAppointee
