import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isNil from 'lodash/isNil'

import { CONSTANTS, utils } from '@epilogue/common'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import mingo from 'mingo'
import { useTranslation } from 'react-i18next'

import Accordion from '../../../../../common/components/atoms/Accordion'
import Drawer from '../../../../../common/components/atoms/Drawer'
import HighlightBubble from '../../../../../common/components/atoms/HighlightBubble'
import commonActions from '../../../../../common/ducks/commonActions'
import commonSelectors from '../../../../../common/ducks/commonSelectors'
import useInjectDynamic from '../../../../../common/hooks/useInjectDynamic'
import amplitude from '../../../../../common/lib/amplitude'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import CorporateExecutorContactForm from './components/CorporateExecutorContactForm'
import RtCalculator from './components/RtCalculator'
import SidebarImage from './components/SidebarImage'
import SidebarList from './components/SidebarList'
import SidebarText from './components/SidebarText'
import SidebarToggleTag from './components/SidebarToggleTag'
import SidebarYoutube from './components/SidebarYoutube'
import WereHereToHelp from './components/WereHereToHelp'
// TAG: Will Power
import WillPowerCalculator from './components/WillPowerCalculator'

const processElement = ({ element, answerStore, referrer, resolveValue }) => {
  const age = utils.dobToAge(answerStore?.dateOfBirth)

  const testObj = {
    referrer,
    answerStore,
    meta: { age },
  }

  // Filter out un-triggered sidebar elements
  if (
    !_isNil(element.trigger) &&
    !new mingo.Query(element.trigger).test(testObj)
  ) {
    return
  }

  /* eslint-disable consistent-return */
  switch (element.type) {
    case CONSTANTS.sidebar.types.ACCORDION:
      return (
        <Accordion
          expandIconColorData={{
            color: themeColorTypes.NEUTRAL,
            colorVariant: themeColorVariants.DARK,
          }}
          dividerColorData={{
            color: themeColorTypes.NEUTRAL,
            colorVariant: themeColorVariants.DARK,
          }}
          data-testid="sidebar-accordion"
          headingData={{
            variant: 'h5',
            color: themeColorTypes.ACCENT_2,
            colorVariant: themeColorVariants.DARKEST,
            label: resolveValue(element.header),
          }}
          onClick={(isOpen) => {
            amplitude.sendEvent('SidebarQuestionToggle', {
              question: element.header,
              isOpening: !isOpen,
            })
          }}
        >
          {React.Children.toArray(
            element.children.map((subElement) =>
              processElement({
                referrer,
                answerStore,
                resolveValue,
                element: subElement,
              }),
            ),
          )}
        </Accordion>
      )
    case CONSTANTS.sidebar.types.TEXT:
      return <SidebarText element={element} />
    case CONSTANTS.sidebar.types.BUBBLE:
      return (
        <HighlightBubble
          variant={element.variant}
          text={resolveValue(element.body)}
        />
      )
    case CONSTANTS.sidebar.types.YOUTUBE:
      return (
        <SidebarYoutube
          youtubeId={element.youtubeId}
          caption={resolveValue(element.caption)}
        />
      )
    case CONSTANTS.sidebar.types.IMAGE:
      return <SidebarImage element={element} />
    case CONSTANTS.sidebar.types.LIST:
      return <SidebarList element={element} />
    case CONSTANTS.sidebar.types.RT_CALCULATOR:
      return <RtCalculator />
    case CONSTANTS.sidebar.types.CORPORATE_EXECUTOR_CONTACT_FORM:
      return <CorporateExecutorContactForm element={element} />
    // TAG: Will Power
    case CONSTANTS.sidebar.types.WILL_POWER_CALCULATOR:
      return <WillPowerCalculator />
    default:
      return <></>
  }
  /* eslint-enable consistent-return */
}

const containerAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
    },
  },
}

const itemAnimation = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

const Sidebar = ({
  data = [],
  referrer,
  questionId,
  sidebarRef,
  answerStore,
  sidebarProperties,
  transitionDuration,
  dispatchUpdateSidebar,
}) => {
  const { resolveValue } = useInjectDynamic()

  const containerRef = useRef(null)

  useEffect(() => containerRef.current.scroll(0, 0), [questionId])

  const HelpContent = (
    <motion.div
      variants={containerAnimation}
      initial="hidden"
      animate="show"
      key={questionId}
    >
      {React.Children.toArray(
        data?.map((element) => (
          <motion.div variants={itemAnimation}>
            {processElement({ element, answerStore, referrer, resolveValue })}
          </motion.div>
        )),
      )}
    </motion.div>
  )

  const { t } = useTranslation()

  return (
    <>
      <SidebarToggleTag sidebarData={data} />
      <Drawer
        open={sidebarProperties.isOpen}
        ref={{ sidebarRef, containerRef }}
        onClose={() => {
          dispatchUpdateSidebar({ isOpen: false })
        }}
        transitionDuration={transitionDuration}
        headerText={t('questionnaire.misc:sidebar.infoCommonQuestions')}
      >
        <LayoutGroup>
          <AnimatePresence exitBeforeEnter>{HelpContent}</AnimatePresence>
          <motion.div layout>
            <WereHereToHelp />
          </motion.div>
        </LayoutGroup>
      </Drawer>
    </>
  )
}

Sidebar.propTypes = {
  questionId: PropTypes.number,
  transitionDuration: PropTypes.number,
  answerStore: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  sidebarRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})])
    .isRequired,
  dispatchUpdateSidebar: PropTypes.func.isRequired,
  sidebarProperties: PropTypes.shape({ isOpen: PropTypes.bool }).isRequired,
  referrer: PropTypes.shape({}).isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateSidebar: (sidebarProperties) =>
    dispatch(commonActions.updateSidebar(sidebarProperties)),
})

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  sidebarProperties: commonSelectors.getSidebar(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
