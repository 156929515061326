import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'

import { Image, Paragraph } from 'grommet'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FeatherDone } from '../../../../../common/assets/images/feather-done.svg'
import Button from '../../../../../common/components/atoms/Button'
import StyledLabel from '../../../../../common/components/atoms/StyledLabel'
import EverythingButton from '../../../../../common/components/molecules/EverythingButton'
import navigationActions from '../../../ducks/navigation/navigationActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'

const Complete = ({ questionnaireValid, dispatchPreviousQuestion }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-center">
        {questionnaireValid ? (
          <>
            <div>
              <StyledLabel>
                {t('customQuestion:Complete.questionnaireValid.label')}
              </StyledLabel>
            </div>
            <StyledLabel>
              {t('customQuestion:Complete.questionnaireValid.label2')}
            </StyledLabel>
            <div className="my-4 max-w-2xl">
              <Paragraph size="smedium">
                {t('customQuestion:Complete.questionnaireValid.subLabel')}
              </Paragraph>
            </div>
          </>
        ) : (
          <div>
            <StyledLabel>
              {t('customQuestion:Complete.questionnaireInvalid.label')}
            </StyledLabel>
            <div className="mt-3 max-w-2xl">
              <Paragraph size="smedium">
                {t('customQuestion:Complete.questionnaireInvalid.subLabel')}
              </Paragraph>
            </div>
          </div>
        )}
      </div>
      <div className="mt-8 mb-14 w-48 sm:w-56">
        {questionnaireValid ? (
          <FeatherDone />
        ) : (
          <Image
            src="https://epilogue-assets-1.s3.ca-central-1.amazonaws.com/puzzle.svg"
            alt="Puzzle with one missing piece"
          />
        )}
      </div>
      <div className="flex flex-col items-center">
        <Box sx={{ mb: '0.9rem' }}>
          <EverythingButton />
        </Box>

        <Link to="/dashboard/summary">
          <Button
            size="xxs"
            iconFontSize="1rem"
            label={t('customQuestion:Complete.button')}
            endIcon={<ArrowForwardIcon />}
          />
        </Link>

        <Button
          size="xxs"
          label={t('common:back')}
          iconFontSize="1rem"
          startIcon={<ArrowBackIcon />}
          onClick={dispatchPreviousQuestion}
          data-testid="create-account-form-back-button"
        />
      </div>
    </div>
  )
}

Complete.propTypes = {
  questionnaireValid: PropTypes.bool,
  dispatchPreviousQuestion: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Complete)
