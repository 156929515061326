import React from 'react'

import { Grid } from '@mui/material'

import { motion } from 'framer-motion'

import { DocumentCardInfo } from '../types/documentCards.types'
import DocumentCard from './DocumentCard'

const animationVariants = {
  hidden: { y: 25, opacity: 0, scale: 0.98 },
  visible: {
    y: 0,
    scale: 1,
    opacity: 1,
    transition: { damping: 14, type: 'spring', stiffness: 100 },
  },
}

interface Props {
  triggerObj: any
  answerStore: any
  cards: DocumentCardInfo[]
  generatedDocuments: any[]
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const DocumentCards = ({
  cards,
  triggerObj,
  answerStore,
  generatedDocuments,
  dispatchSetGlobalErrorMessage,
}: Props) => (
  <Grid
    container
    spacing="2rem"
    sx={{
      mb: { min: '3.2rem', xl: '0' },
      pt: { min: '1rem', xl: '0' },
      justifyContent: { min: 'center', lg: 'flex-start' },
    }}
  >
    {React.Children.toArray(
      cards.map((cardInfo, index) => {
        const generatedDocument = generatedDocuments.find(
          (generatedDoc) => generatedDoc.docType === cardInfo.docType,
        )

        return (
          <Grid item component={motion.div} variants={animationVariants}>
            <DocumentCard
              {...cardInfo}
              triggerObj={triggerObj}
              answerStore={answerStore}
              generatedDocument={generatedDocument}
              dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
            />
          </Grid>
        )
      }),
    )}
  </Grid>
)

export default DocumentCards
