import { CharityCategory, CONSTANTS, Province } from '@epilogue/common'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { errorHandler, PATHS, request } from '../../../request'
import { queryKeys, queryKeysBase } from '../../../utils/queryKeys'

const queryLimit = 20

interface UseRecommendedCharityQueryProps {
  query?: string
  category?: CharityCategory
  province?: Province | null
  dispatchSetGlobalErrorMessage: (errorMessage: string) => void
}

type LanguageIds =
  (typeof CONSTANTS.languageTypes)[keyof typeof CONSTANTS.languageTypes]['id']

type LanguageStringValues = {
  [key in LanguageIds]: string
}

export interface RecommendedCharity {
  name: string
  description?: LanguageStringValues
  category?: CharityCategory
  number?: string
  location: string
}

interface RecommendedCharityResponse {
  items: RecommendedCharity[]
  totalPages: number
}

interface Page extends RecommendedCharityResponse {
  currentPage: number
}

const useRecommendedCharityQuery = ({
  query,
  province,
  category,
  dispatchSetGlobalErrorMessage,
}: UseRecommendedCharityQueryProps) =>
  useInfiniteQuery({
    // Values are cast to string to explicitly define the difference between null and undefined
    queryKey: queryKeys[queryKeysBase.recommendedCharities](
      String(query),
      // @ts-expect-error 🤬🤬🤬
      String(province),
      category,
    ),

    queryFn: async ({ pageParam: page = 1 }): Promise<Page> => {
      const {
        data: { payload },
      } = (await request({
        method: 'GET',
        url: PATHS.CHARITY,
        shouldSerializeParams: true,
        params: { q: query, province, category, page, limit: queryLimit },
      })) as AxiosResponse<{
        payload: RecommendedCharityResponse
      }>

      return {
        ...payload,
        currentPage: page,
      }
    },

    getNextPageParam: (lastPage: Page) => {
      const { currentPage, totalPages } = lastPage

      return currentPage < totalPages ? currentPage + 1 : undefined
    },

    retry: process.env.REACT_APP_ENV === 'production' ? 1 : 0,
    refetchOnWindowFocus: false,
    staleTime: 15 * 60 * 1000,

    onError: (error: AxiosError) => {
      errorHandler({
        error,
        reportError: true,
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    },
  })

export default useRecommendedCharityQuery
