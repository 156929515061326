import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'

import { alpha, Box } from '@mui/material'

import { AnimatePresence, motion } from 'framer-motion'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import growAnimation from '../../../../../../common/animations/growAnimation'
import { ReactComponent as SuccessCheck } from '../../../../../../common/assets/images/success-check.svg'
import Button from '../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../common/components/atoms/Heading'
import Select from '../../../../../../common/components/atoms/Select'
import Text from '../../../../../../common/components/atoms/Text'
import TextInput from '../../../../../../common/components/atoms/TextInput/TextInput'
import commonActions from '../../../../../../common/ducks/commonActions'
import { errorHandler, PATHS, request } from '../../../../../../common/request'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'
import defaultCorpExec from '../../../../data/corporateExecutors'
import questionnaireSelectors from '../../../../ducks/questionnaire/questionnaireSelectors'
import { emailRegex } from '../../../../utils/validation/patterns'
import linkStyle from './helpers/linkStyle'

const animations = {
  enter: {
    x: 0,
    opacity: 1,
    transition: { bounce: 0, duration: 0.55, type: 'spring' },
  },
  leave: {
    opacity: 0,
    transition: {
      x: { duration: 0.55 },
      opacity: { duration: 0.35 },
    },
    x: -60,
  },
}

const CorporateExecutorContactForm = ({
  answerStore,
  element,
  dispatchSetGlobalErrorMessage,
}) => {
  const [working, setWorking] = useState(false)
  const [confirmedSend, setConfirmedSend] = useState(false)
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const [email, setEmail] = useState(answerStore?.email || '')
  const [displayValidation, setDisplayValidation] = useState(false)

  const { header, body } = element
  const validEmail = emailRegex.test(email)

  const { t } = useTranslation()

  const onSubmit = async () => {
    if (subject && content && validEmail) {
      try {
        setWorking(true)

        await request({
          method: 'POST',
          url: PATHS.CORPORATE_EXECUTOR_CONTACT_FORM,
          data: {
            corporateExecutorRef: defaultCorpExec.ref,
            subject,
            content,
            userData: { email, firstName: answerStore?.firstName },
          },
        })

        setConfirmedSend(true)
      } catch (error) {
        errorHandler({
          error,
          fallbackErrorMessage: `Failed to send message to ${t(
            _get(
              defaultCorpExec.displayName,
              'tKey',
              defaultCorpExec.displayName,
            ),
          )}. Please refresh your browser and try again.`,
          reportError: true,
          onError: (resolvedErrorMessage) => {
            dispatchSetGlobalErrorMessage(resolvedErrorMessage)
          },
        })
      } finally {
        setWorking(false)
      }
    } else {
      setDisplayValidation(true)
    }
  }

  const contactSubjectOptions = useMemo(
    () => [
      t(
        'sidebar:components.CorporateExecutorContactForm.contactSubjectOptions.item1',
      ),
      t(
        'sidebar:components.CorporateExecutorContactForm.contactSubjectOptions.item2',
      ),
      t(
        'sidebar:components.CorporateExecutorContactForm.contactSubjectOptions.item3',
      ),
      t(
        'sidebar:components.CorporateExecutorContactForm.contactSubjectOptions.item4',
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
        overflow: 'hidden',
        pt: '1rem',
        mb: '1rem',
      }}
      data-testid="corporate-executor-contact-form"
    >
      {header && (
        <Heading
          variant="h5"
          color={themeColorTypes.ACCENT_2}
          colorVariant={themeColorVariants.DARKEST}
        >
          {t(_get(header, 'tKey', header))}
        </Heading>
      )}
      {body && (
        <Text sx={{ lineHeight: 1.4, ...linkStyle }} variant="paragraph">
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(_get(body, 'tKey', body)),
            }}
          />
        </Text>
      )}
      <Box
        sx={{
          borderRadius: (theme) => theme.shape.borderRadiusMedium,
          backgroundColor: (theme) =>
            alpha(
              theme.palette[themeColorTypes.ACCENT_2][
                themeColorVariants.LIGHTER
              ],
              0.3,
            ),
          px: '1rem',
          py: '1.25rem',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            variants={animations}
            animate="enter"
            exit="leave"
            key={confirmedSend ? 'sent' : 'unsent'}
          >
            {!confirmedSend ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.65rem',
                }}
              >
                <Text
                  size="lg"
                  align="left"
                  variant="display"
                  sx={{ mb: '-0.5rem' }}
                >
                  {t('sidebar:components.CorporateExecutorContactForm.label')}
                </Text>
                <Text
                  size="xs"
                  align="left"
                  variant="paragraph"
                  sx={{ mb: '0.5rem' }}
                >
                  {t(
                    'sidebar:components.CorporateExecutorContactForm.subLabel',
                  )}
                </Text>

                {answerStore?.firstName && (
                  <Box sx={{ mb: '-1.15rem' }}>
                    <TextInput
                      value={answerStore.firstName}
                      disabled
                      variant="outlined"
                      placeholder={t('common:firstName')}
                      sx={{
                        borderRadius: (theme) => theme.shape.borderRadius,
                        backgroundColor: (theme) =>
                          theme.palette[themeColorTypes.WHITE][
                            themeColorVariants.MAIN
                          ],
                      }}
                    />
                  </Box>
                )}

                <Select
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette[themeColorTypes.WHITE][
                        themeColorVariants.MAIN
                      ],
                    borderRadius: (theme) => theme.shape.borderRadius,
                  }}
                  selectId="emailSubject"
                  value={subject}
                  placeholder={t('common:subject')}
                  isInvalid={displayValidation && !subject}
                  onInputChange={setSubject}
                  options={contactSubjectOptions.map((value) => ({
                    value,
                    label: value,
                  }))}
                />
                {!answerStore?.email && (
                  <Box sx={{ mb: '-1.15rem' }}>
                    <TextInput
                      value={email}
                      variant="outlined"
                      placeholder={t('common:email')}
                      onInputChange={setEmail}
                      validationMessage={t(
                        'validation:validation.enterValidEmail',
                      )}
                      isInvalid={
                        (!email || (email && !validEmail)) && displayValidation
                      }
                      sx={{
                        borderRadius: (theme) => theme.shape.borderRadius,
                        backgroundColor: (theme) =>
                          theme.palette[themeColorTypes.WHITE][
                            themeColorVariants.MAIN
                          ],
                      }}
                    />
                  </Box>
                )}
                <TextInput
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette[themeColorTypes.WHITE][
                        themeColorVariants.MAIN
                      ],
                    borderRadius: (theme) => theme.shape.borderRadius,
                  }}
                  value={content}
                  onInputChange={setContent}
                  isInvalid={displayValidation && !content}
                  variant="outlined"
                  placeholder={t('common:message')}
                  rows="4"
                  type="textarea"
                />
                <Button
                  label={t('common:sendMessage')}
                  fullWidth
                  variant="contained"
                  disabled={working}
                  onClick={onSubmit}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <motion.div data-testid="success-animation" {...growAnimation}>
                  <SuccessCheck style={{ width: '2.5rem' }} />
                </motion.div>
                <Text size="lg" align="center" variant="display">
                  {t('common:success')}!
                </Text>
                <Text variant="paragraph" align="center" sx={{ mb: '0.25rem' }}>
                  {t(
                    'sidebar:components.CorporateExecutorContactForm.successMessage',
                    {
                      name: _get(
                        defaultCorpExec.displayName,
                        'tKey',
                        defaultCorpExec.displayName,
                      ),
                      email,
                    },
                  )}
                </Text>
              </Box>
            )}
          </motion.div>
        </AnimatePresence>
      </Box>
    </Box>
  )
}

CorporateExecutorContactForm.propTypes = {
  element: PropTypes.shape({
    header: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    body: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
  }).isRequired,

  answerStore: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    dateOfBirth: PropTypes.string,
  }).isRequired,

  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorporateExecutorContactForm)
