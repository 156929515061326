import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import { Box } from '@mui/material'

import { Button, Paragraph, Text } from 'grommet'
import styled from 'styled-components'

import HighlightPill from '../../../../../common/components/molecules/HighlightPill'
import useInjectDynamic from '../../../../../common/hooks/useInjectDynamic'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import tw from '../../../../../common/styles/tw'
import navigationActions from '../../../ducks/navigation/navigationActions'

const ButtonsWrapper = styled.div`
  & button {
    height: ${({ hasPill }) => (hasPill ? '9.2rem' : '8.7rem')};
  }
`
const StyledButton = styled(Button)`
  color: ${tw.colors.brand};
  border: ${({ answeredFieldValue, optionValue, isInvalid }) =>
    isInvalid
      ? `${tw.borderWidth['2']} ${
          themeColors[themeColorTypes.RED][themeColorVariants.LIGHT]
        } solid;`
      : !!answeredFieldValue && answeredFieldValue === optionValue
        ? `${tw.borderWidth['2']} #8479cc solid;`
        : `${tw.borderWidth['2']} ${tw.colors['accent-2-lighter']} solid;`}

  width: ${({ options }) => {
    /*
     * only when there are 3 options, use a custom width so that
     * the 3 options sit side-by-side
     */
    if (options.length === 3) {
      return '15rem;'
    }

    return `${tw.spacing['64']};` // 16rem
  }};
  
  border-radius: ${tw.borderRadius.lg};
  
  &:hover {
    box-shadow: none;
    border: ${({ answeredFieldValue, optionValue }) =>
      !!answeredFieldValue && answeredFieldValue === optionValue
        ? `${tw.borderWidth['2']} #8479cc solid;`
        : `${tw.borderWidth['2']} ${tw.colors['accent-2-dark']} solid;`}
  }
`
const HighlightPillWrapper = styled.div`
  top: 100%;
  right: 50%;
  transform: translateX(50%);
`

const QuickRadioField = ({
  field,
  isInvalid = false,
  answerCache,
  validationMessage,
  dispatchNavNextAutomatic,
  handleFieldFragmentUpdate,
}) => {
  const { resolveValue } = useInjectDynamic()

  const { name, options } = field
  const answeredFieldValue = answerCache[name] || undefined
  const hasPill = !_isEmpty(options) && options.some((o) => o.pill)

  const handleSetFieldValue = (val) => {
    handleFieldFragmentUpdate({ [name]: val })
    /*
     * when a QuickRadioField's button is selected, dispatch navNextAutomatic action
     * the operations middleware decides whether or not to follow through
     * on the auto advance, but it still gets triggered here
     */
    dispatchNavNextAutomatic({ fragment: { [name]: val } })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {isInvalid && validationMessage && (
        <Box sx={{ mb: '1rem' }}>{validationMessage}</Box>
      )}
      <ButtonsWrapper
        hasPill={hasPill} // for styling
        data-testid="quick-radio-buttons-wrapper"
        className="flex flex-wrap justify-center items-center"
      >
        {!_isEmpty(options) &&
          options.map((o, i) => {
            const resolvedValue = resolveValue(o.value)

            return (
              <div className="p-2" key={o.value}>
                <StyledButton
                  data-testid={`${name}-quick-radio-${i}`}
                  primary
                  disabled={o.disabled}
                  color="white"
                  options={options} // for styling
                  isInvalid={isInvalid} // for styling
                  optionValue={resolvedValue} // for styling
                  answeredFieldValue={answeredFieldValue} // for styling
                  label={
                    <>
                      <div className="relative">
                        <Text
                          data-testid="quick-radio-field-label"
                          weight={o.subLabel ? 'bold' : 'normal'}
                        >
                          {resolveValue(o.label)}
                        </Text>

                        {o.pill && (
                          <HighlightPillWrapper className="absolute inline-block mt-1.5 w-full">
                            <HighlightPill label={resolveValue(o.pill)} />
                          </HighlightPillWrapper>
                        )}
                      </div>

                      {o.subLabel && (
                        <div className="mt-2">
                          <Paragraph size="0.9rem" fill>
                            {resolveValue(o.subLabel)}
                          </Paragraph>
                        </div>
                      )}
                    </>
                  }
                  onClick={() => handleSetFieldValue(resolvedValue)}
                />
              </div>
            )
          })}
      </ButtonsWrapper>
    </Box>
  )
}

QuickRadioField.propTypes = {
  field: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isInvalid: PropTypes.bool,
  validationMessage: PropTypes.node,
  answerCache: PropTypes.shape({}).isRequired,
  dispatchNavNextAutomatic: PropTypes.func.isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchNavNextAutomatic: ({ fragment }) =>
    dispatch(navigationActions.navNextAutomatic({ fragment })),
})

export default connect(null, mapDispatchToProps)(QuickRadioField)
