import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Box, Checkbox, Tooltip } from '@mui/material'

import { motion } from 'framer-motion'

import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'

const animation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.13 },
}

const InitialArea = forwardRef(
  ({ firstName, lastName, sx = {}, isSigned, handleInitialsCheck }, ref) => {
    const firstInitial = firstName[0]
    const lastInitial = lastName[0]

    return (
      <Box
        ref={ref}
        sx={{
          my: '0.8rem',
          mr: '0.5rem',
          ml: 'auto',
          px: '0.5rem',
          py: '0.5rem',
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: (theme) => theme.shape.borderRadius,
          backgroundColor: (theme) =>
            isSigned
              ? theme.palette[themeColorTypes.WHITE][themeColorVariants.MAIN]
              : theme.palette[themeColorTypes.YELLOW][themeColorVariants.LIGHT],
          '& input, & svg': {
            color: (theme) =>
              !isSigned
                ? theme.palette[themeColorTypes.RED][themeColorVariants.MAIN]
                : undefined,
          },
          justifyContent: 'flex-end',
          ...sx,
        }}
      >
        <Checkbox
          sx={{ p: 0 }}
          checked={isSigned}
          onChange={(e) => handleInitialsCheck(e.target.checked)}
        />
        <Tooltip
          title={
            isSigned
              ? 'Uncheck the box to the left to remove initial'
              : 'Check the box to the left to initial'
          }
          placement="top"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '3rem',
              alignItems: 'center',
              height: '2rem',
              justifyContent: 'flex-end',
            }}
          >
            {isSigned && (
              <motion.div
                {...animation}
                style={{ fontFamily: "'Homemade Apple', cursive" }}
              >
                {firstInitial}
                {lastInitial}
              </motion.div>
            )}
            <Box
              sx={{
                borderTop: 'solid 1px',
              }}
            >
              Initials
            </Box>
          </Box>
        </Tooltip>
      </Box>
    )
  },
)

InitialArea.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  handleInitialsCheck: PropTypes.func.isRequired,
  isSigned: PropTypes.bool.isRequired,
  sx: PropTypes.shape({}),
}

export default InitialArea
