import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'
import _omit from 'lodash/omit'
import _toNumber from 'lodash/toNumber'
import _toString from 'lodash/toString'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Collapse } from '@mui/material'

import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../common/components/atoms/Button'
import IconButton from '../../../../../common/components/atoms/IconButton'
import Select from '../../../../../common/components/atoms/Select'
import TextInput from '../../../../../common/components/atoms/TextInput'
import CharityFinder from '../../../../../common/components/CharityFinder'
import PieChart from '../../../../../common/components/PieChart'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import relationshipOptions from '../../../data/options/relationshipOptions'
import navigationActions from '../../../ducks/navigation/navigationActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import CharitySearchInput from '../../molecules/CharitySearchInput/CharitySearchInput'
import NavigationButtons from '../../molecules/NavigationButtons'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'
import PortionsHelpModal from './components/PortionsHelpModal'
import portionOptions from './data/portionOptions'
import modifiedRelationshipOptions from './utils/modifiedRelationshipOptions'

const newBeneficiary = (type) => ({
  type,
  name: '',
  portions: '1',
  id: Date.now().toString(),
  relationship: type === 'person' ? undefined : 'charitableOrg',
})

const rowAnimation = {
  initial: {
    maxHeight: 0,
    opacity: 0,
    y: -115,
  },
  animate: {
    maxHeight: '12rem',
    opacity: 1,
    y: 0,

    transition: {
      y: {
        mass: 1,
        damping: 16,
        type: 'spring',
        stiffness: 100,
      },
      opacity: {
        duration: 0.35,
        delay: 0.126,
      },
      maxHeight: { duration: 0.35 },
    },
  },
  exit: {
    opacity: 0,
    maxHeight: 0,
    visibility: 'hidden',

    transition: {
      maxHeight: { duration: 0.3 },
    },
  },
}

const RemoteDistribution = ({
  question,
  answerCache,
  displayValidation,
  questionFragments = {},
  dispatchNextQuestion,
  handleFieldFragmentUpdate,
}) => {
  const initialRender = useRef(true)

  const [field] = question.fields

  const [beneficiaries, setBeneficiaries] = useState(
    answerCache.remoteDistribution || [],
  )
  const [displayCharityFinder, setDisplayCharityFinder] = useState(false)

  const [pieData, setPieData] = useState([])
  const [hasDisplayedPortionsHelpModal, setHasDisplayedPortionsHelpModal] =
    useState(false)

  useEffect(() => {
    if (initialRender.current === false) {
      handleFieldFragmentUpdate({
        /*
         * omitting the following properties for each beneficiary
         * is for the Predeceased and PredeceasedBackup questions
         */
        [field.name]: beneficiaries.map((b) =>
          _omit(b, [
            'predeceasedSplit',
            'alternateName',
            'alternateNumber',
            'alternateLocation',
            'alternateRelationship',
          ]),
        ),
        /*
         * anytime remoteDistribution changes, reset 'predeceased' and
         * 'predeceasedBackup' in the answerStore
         */
        predeceased: undefined,
        predeceasedBackup: undefined,
      })
    } else {
      initialRender.current = false
    }

    const thePieData = beneficiaries.map((b, i) => ({
      id: i,
      title: b.name,
      value: _toNumber(b.percentage),
    }))

    setPieData(thePieData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries])

  const handleUpdate = (theBeneficiaries) => {
    const totalPortions = theBeneficiaries.reduce(
      (acc, currentVal) => acc + _toNumber(currentVal.portions),
      0,
    )

    // calculates percentage for all beneficiaries
    const beneficiariesWithPercentage = theBeneficiaries.map((b) => ({
      ...b,
      percentage: _toString(
        Math.round((_toNumber(b.portions) / totalPortions) * 100 * 100) / 100,
      ),
    }))

    setBeneficiaries(beneficiariesWithPercentage)
  }

  const updateValues = (beneficiaryId, updates) => {
    const beneficiariesWithUpdate = beneficiaries.map((b) =>
      b.id === beneficiaryId ? { ...b, ...updates } : b,
    )
    handleUpdate(beneficiariesWithUpdate)
  }

  const multipleBeneficiaries = beneficiaries.length > 1

  const { t } = useTranslation()

  return (
    <>
      <CharityFinder
        show={displayCharityFinder}
        setShow={setDisplayCharityFinder}
        handleAddCharity={({ name, number, location }) => {
          const newCharityBeneficiary = {
            ...newBeneficiary('charity'),
            name,
            number,
            location,
          }
          setDisplayCharityFinder(false)
          handleUpdate([...beneficiaries, newCharityBeneficiary])
        }}
      />
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '0.75rem',
          }}
        >
          <ValidationMessage
            question={question}
            message={
              !_isEmpty(beneficiaries)
                ? undefined
                : t('customQuestion:RemoteDistribution.validationMessage')
            }
          />
        </Box>

        <AnimatePresence initial={false}>
          {!_isEmpty(beneficiaries) &&
            beneficiaries.map(
              ({ id, type, name, relationship, portions }, index) => (
                <Box
                  component={motion.div}
                  {...rowAnimation}
                  key={id}
                  sx={{
                    mx: 'auto',
                    width: '100%',
                    maxWidth: '56rem',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      pb: '0.75rem',
                      pr: { min: '1rem', lg: 'unset' },
                      mt:
                        index !== 0 ? { min: '0.8rem', lg: '1.3rem' } : '0rem',
                      maxHeight: { min: 'unset', lg: '5rem' },
                    }}
                  >
                    <Collapse
                      in={multipleBeneficiaries}
                      orientation="horizontal"
                      timeout={multipleBeneficiaries ? 185 : 90}
                      easing={{ enter: 'linear', exit: 'ease-in' }}
                      unmountOnExit
                    >
                      <Box
                        sx={{
                          width: '2rem',
                          height: '2rem',
                          marginRight: '1rem',
                        }}
                      >
                        <IconButton
                          IconComponent={
                            <CloseIcon sx={{ fontSize: '1.2rem' }} />
                          }
                          color={themeColorTypes.GREY}
                          colorVariant={themeColorVariants.LIGHT}
                          onClick={() =>
                            handleUpdate(
                              beneficiaries.filter((b) => b.id !== id),
                            )
                          }
                        />
                      </Box>
                    </Collapse>

                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        row: '1rem',
                        alignItems: 'center',
                        pl: {
                          min: '1rem',
                          lg: '0rem',
                        },
                      }}
                    >
                      <Box sx={{ flex: { min: '0 0 100%', lg: '1 2 50%' } }}>
                        {type === 'charity' ? (
                          <Box
                            sx={{
                              position: 'relative',
                              top: '0.5rem',
                            }}
                          >
                            <CharitySearchInput
                              value={name}
                              isInvalid={displayValidation && !name}
                              validationMessage=""
                              placeholder={t(
                                'customQuestion:RemoteDistribution.placeholder.charityName',
                              )}
                              onCharitySearchInputChange={({
                                // eslint-disable-next-line @typescript-eslint/no-shadow
                                name,
                                number,
                                location,
                              }) => {
                                updateValues(id, { name, number, location })
                              }}
                            />
                          </Box>
                        ) : (
                          <TextInput
                            value={name}
                            isInvalid={displayValidation && !name}
                            validationMessage=""
                            placeholder={t(
                              'customQuestion:RemoteDistribution.placeholder.indivFullName',
                            )}
                            onInputChange={(val) =>
                              updateValues(id, { name: val })
                            }
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          flex: { min: '1', lg: '2 1 30%' },
                          pl: {
                            min: '0rem',
                            lg: '1rem',
                          },
                        }}
                      >
                        {type === 'charity' ? (
                          // Filler dropdown for charities, which don't need a relationship
                          <Select
                            selectId="charityPlaceholder"
                            disabled
                            placeholder={t(
                              'customQuestion:RemoteDistribution.placeholder.charity',
                            )}
                            options={[]}
                            sx={{
                              '& svg': { display: 'none' },
                            }}
                            onInputChange={() => {}}
                          />
                        ) : (
                          <Select
                            value={relationship}
                            isInvalid={displayValidation && !relationship}
                            validationMessage=""
                            selectId={id}
                            options={[
                              ...modifiedRelationshipOptions(
                                relationshipOptions,
                                answerCache,
                                // Send tKey as label for translation
                              ).map(({ value, tKey }) => ({
                                value,
                                label: { tKey },
                              })),
                            ]}
                            placeholder={t('common:thisPersonIsMy')}
                            onInputChange={(val) =>
                              updateValues(id, { relationship: val })
                            }
                          />
                        )}
                      </Box>

                      <Collapse
                        in={multipleBeneficiaries}
                        orientation="horizontal"
                        timeout={multipleBeneficiaries ? 185 : 90}
                        unmountOnExit
                      >
                        <Box
                          sx={{
                            width: '6rem',
                            height: '4.1rem',
                            display: 'flex',
                            alignItems: 'center',
                            pl: '1rem',
                          }}
                        >
                          <Select
                            value={portions}
                            selectId={id}
                            options={portionOptions}
                            placeholder={t(
                              'customQuestion:RemoteDistribution.portions',
                            )}
                            onInputChange={(val) =>
                              updateValues(id, { portions: val })
                            }
                          />
                        </Box>
                      </Collapse>
                    </Box>
                  </Box>
                </Box>
              ),
            )}
        </AnimatePresence>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { min: 'column', md: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            mt: { min: '3rem', md: '3.75rem' },
            mb: '1.2rem',
            gap: '1rem',

            '& button': {
              fontSize: '0.9rem',
              py: '0.5rem',
              width: '11.95rem',
              fontWeight: 'bold',
            },
          }}
        >
          <Button
            variant="contained"
            label={t('customQuestion:RemoteDistribution.button.addIndiv')}
            startIcon={<AddIcon />}
            data-testid="add-individual-button"
            color={themeColorTypes.NEUTRAL}
            colorVariant={themeColorVariants.DARK}
            onClick={() =>
              handleUpdate([...beneficiaries, newBeneficiary('person')])
            }
          />

          <Button
            variant="contained"
            label={t('customQuestion:RemoteDistribution.button.addCharity')}
            startIcon={<AddIcon />}
            data-testid="add-charity-button"
            color={themeColorTypes.NEUTRAL}
            colorVariant={themeColorVariants.DARK}
            onClick={() => setDisplayCharityFinder(true)}
          />
        </Box>

        {!_isEmpty(beneficiaries) && (
          <Box sx={{ pb: '1rem', my: '4rem' }}>
            <PieChart data={pieData} />
          </Box>
        )}

        <NavigationButtons
          handleButtonClick={() => dispatchNextQuestion(questionFragments)}
        />

        {multipleBeneficiaries &&
          !hasDisplayedPortionsHelpModal &&
          (_isEmpty(answerCache.remoteDistribution) ||
            answerCache.remoteDistribution.length < 2) && (
            <PortionsHelpModal
              toggleHasDisplayedPortionsHelpModal={() => {
                setHasDisplayedPortionsHelpModal(true)
              }}
            />
          )}
      </Box>
    </>
  )
}

RemoteDistribution.propTypes = {
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  answerCache: PropTypes.shape({
    remoteDistribution: PropTypes.arrayOf(PropTypes.shape({})),
    partnerFirstName: PropTypes.string,
  }).isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  displayValidation: PropTypes.bool.isRequired,
  dispatchNextQuestion: PropTypes.func.isRequired,
  questionFragments: PropTypes.shape({}),
}

const mapStateToProps = (state) => ({
  questionFragments: questionnaireSelectors.getQuestionFragments(
    state.questionnaire,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchNextQuestion: (questionFragments) =>
    dispatch(navigationActions.nextQuestion({ questionFragments })),
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoteDistribution)
