import React from 'react'
import PropTypes from 'prop-types'

import { Routes, useLocation } from 'react-router-dom'

import { AnimatePresence } from 'framer-motion'

const AnimatedRouter = ({ children }) => {
  const location = useLocation()

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Routes location={location} key={location.pathname}>
        {children}
      </Routes>
    </AnimatePresence>
  )
}

AnimatedRouter.propTypes = { children: PropTypes.node.isRequired }

export default AnimatedRouter
