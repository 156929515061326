import React, { useContext } from 'react'

import _isEmpty from 'lodash/isEmpty'

import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../../common/components/atoms/Heading'
import LoadingSpinner from '../../../../../../../common/components/atoms/LoadingSpinner'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import LawyerReferralContext from '../../context/LawyerReferralContext'
import LawyerCards from './components/LawyerCards'

const useStyles = makeStyles({ heading: { fontSize: '1.27rem' } })

const StandardLawyerReferral = () => {
  const classes = useStyles()

  const { lawyers } = useContext(LawyerReferralContext)

  const { t } = useTranslation()

  return (
    <>
      {!lawyers ? (
        // TODO: add an actual empty state as opposed to just a spinner
        <LoadingSpinner />
      ) : (
        <>
          {!_isEmpty(lawyers) && (
            <>
              <Box textAlign="center" pb="3.8rem">
                <Heading
                  variant="h3"
                  align="center"
                  className={classes.heading}
                  color={themeColorTypes.ACCENT_1}
                >
                  {t('molecules:LawyerReferral.virtualMeetingPillLabel')}
                </Heading>
              </Box>

              <LawyerCards lawyers={lawyers} />
            </>
          )}
        </>
      )}
    </>
  )
}

export default StandardLawyerReferral
