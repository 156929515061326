import { Dispatch, useReducer } from 'react'

import { CharityCategory, Province } from '@epilogue/common'

import useDebounce from '../../../hooks/useDebounce'

export type ProvinceSelectValue = Province | 'all' | 'canada'
export type CategorySelectValue = CharityCategory | 'all'

export interface QueryState {
  // Parameters for API calls
  query?: string
  category?: CharityCategory
  province?: Province | null

  // Values for inputs and selects
  queryInputValue: string
  provinceInputValue: ProvinceSelectValue
  categoryInputValue: CategorySelectValue
}

export enum QueryActionType {
  UpdateSearchQuery = 'updateSearchQuery',
  UpdateProvince = 'updateProvince',
  UpdateCategory = 'updateCategory',
  ResetQuery = 'resetQuery',
}

export type QueryAction =
  | {
      type: QueryActionType.UpdateSearchQuery
      value: string
    }
  | {
      type: QueryActionType.UpdateProvince
      value: ProvinceSelectValue
    }
  | {
      type: QueryActionType.UpdateCategory
      value: CategorySelectValue
    }
  | {
      type: QueryActionType.ResetQuery
      value?: never
    }

const mapProvinceToState = (value: ProvinceSelectValue) => {
  switch (value) {
    case 'all':
      return undefined
    case 'canada':
      return null
    default:
      return value
  }
}

const mapCategoryToState = (value: CategorySelectValue) => {
  switch (value) {
    case 'all':
      return undefined
    default:
      return value
  }
}

const defaultQueryState: QueryState = {
  query: undefined,
  category: undefined,
  province: undefined,

  queryInputValue: '',
  provinceInputValue: 'all',
  categoryInputValue: 'all',
}

const reducer = (state: QueryState, action: QueryAction): QueryState => {
  const { type, value } = action

  switch (type) {
    case QueryActionType.UpdateSearchQuery:
      return {
        ...state,
        query: value || undefined,
        queryInputValue: value,
      }
    case QueryActionType.UpdateCategory:
      return {
        ...state,
        category: mapCategoryToState(value),
        categoryInputValue: value,
      }
    case QueryActionType.UpdateProvince:
      return {
        ...state,
        province: mapProvinceToState(value),
        provinceInputValue: value,
      }
    case QueryActionType.ResetQuery:
      return defaultQueryState
    default:
      return state
  }
}

const useCharityFinderQueryReducer = (): [
  QueryState,
  Dispatch<QueryAction>,
] => {
  const [state, dispatch] = useReducer(reducer, defaultQueryState)

  const debouncedQuery = useDebounce(state.query, 300) as string | undefined

  const debouncedState = {
    ...state,
    query: debouncedQuery,
  }

  return [debouncedState, dispatch]
}

export default useCharityFinderQueryReducer
